import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, InputAdornment, Portal, TextField } from '@mui/material';
import { ReactAnglePicker } from 'react-angle-picker';

//import ColorPicker from 'react-native-wheel-color-picker'
//import NumericInput from 'react-native-numeric-input'

const EditWeathercolumnDialog = ({open, column, saveWeathercolumn, setEditWeathercolumnDialogVisible}) => {  
    console.log("EditWeathercolumnDialog: ", open, column)
    
    const colorPickerRef = React.useRef("colorPicker");

    const MIN_VALUE = 0
    const MAX_VALUE = 360

    const [lower_limit, setLowerLimit] = useState(column.lower_limit);
    const [upper_limit, setUpperLimit] = useState(column.upper_limit);
    const [limits, setLimits] = useState([column.lower_limit, column.upper_limit]);

    /**
   * Change lower limit of weathercolumn
   * @param {*} lower_limit 
   */
  const changelower_limit = (lower_limit) => {
    setLowerLimit(lower_limit)

    column.lower_limit = lower_limit

  }

    /**
   * Change upper limit of weathercolumn
   * @param {*} upper_limit 
   */
    const changeupper_limit = (upper_limit) => {
      setUpperLimit(upper_limit)
      column.upper_limit = upper_limit
    }

   /**
   * Change upper limit of weathercolumn
   * @param {*} limits 
   */
   const changeLimits = (e) => {
    console.log("changeLimits", e.target.value)

    let limits = e.target.value
    setLimits(limits)

    column.lower_limit = limits[0]
    column.upper_limit = limits[1]

  }

    return <div>
    <Dialog
      open={open}
      onClose={() => setEditWeathercolumnDialogVisible(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {column?.name} editieren
      </DialogTitle>
      <DialogContent>
        <Box sx={{flexDirection: 'column', mt: 2, ml:1, mr:1}}>
        <TextField 
        variant='standard'
        type="number" value={lower_limit}
        sx={{ m: 1, width: '10ch' }}
        onChange={event => changelower_limit(parseInt(event.target.value))}
        placeholder='Low lim.'
        InputProps={{
          endAdornment: <InputAdornment position="start">{column.unit}</InputAdornment>,
          label: "Low lim."
        }}
      />
      <TextField 
        variant='standard'
        type="number" value={upper_limit}
        sx={{ m: 1, width: '10ch' }}
        onChange={event => changeupper_limit(parseInt(event.target.value))}
        placeholder='High lim.'
        InputProps={{
          endAdornment: <InputAdornment position="start">{column.unit}</InputAdornment>,
          label: "High lim."
        }}
      />
        </Box>
         
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditWeathercolumnDialogVisible(false)}>Abbrechen</Button>
        <Button onClick={() => {
            column.lower_limit = lower_limit
            column.upper_limit = upper_limit
            saveWeathercolumn(column)

            setEditWeathercolumnDialogVisible(false)
        }} autoFocus>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  </div>
    
    
    {/*<Dialog
                    open={open} 
                >
                    <Box style={{flexDirection: 'row', alignItems: 'center', marginHorizontal: 8}}>  
                        {<Text 
                            variant="subtitle"
                            style={{marginLeft: 8}}
                        >
                            {column?.name} editieren
</Text>}
                    </Box>  
                    <Dialog.ScrollArea style={{flex: 0, flexDirection: 'column', justifyContent: 'center'}}>

                            <Box sx={{mt: 2, ml:1, mr:1}}>
                                <ReactAnglePicker
                                value={angle}
                                onChange={changeAngle}
                                onAfterChange={afterChangeAngle}
                                />
                            </Box>
                            <TextField 
                                variant='standard'
                                type="number" value={angle}
                                sx={{ m: 1, width: '10ch' }}
                                placeholder='Angle'
                                onChange={event => afterChangeAngle(parseInt(event.target.value))}
                                InputProps={{
                                endAdornment: <InputAdornment position="start">°</InputAdornment>,
                                label: "Angle",
                                min: MIN_VALUE, max: MAX_VALUE
                                }}
                            />                            
                    </Dialog.ScrollArea>
                    <Dialog.Actions>
                        <Button key={"Abbrechen"} onPress={() => setEditWeathercolumnDialogVisible(false)}>Abbrechen</Button>  
                        <Button key={"Speichern"} onPress={() => {
                            column.color = hexColor;
                            column.angle = angle;
                            saveWeathercolumn(column)
                        }}>Speichern</Button>                     
                    </Dialog.Actions> 
                </Dialog>*/}
}
export default EditWeathercolumnDialog