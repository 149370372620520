import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Menu from '../components/Menu';
import Header from '../components/Header';
import Select from 'react-select'

//import 'mapbox-gl/dist/mapbox-gl.css';
//import './index.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ScatterController,
  BubbleController,
  Filler,
  Title,
  Tooltip,
  Legend, 
  TimeScale
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';

import { Chart } from 'react-chartjs-2';

import Footer from '../components/Footer';
import { Box, Card, Container, FormControl, FormGroup, FormLabel, Grid, IconButton, TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import PillarCheckBox from '../components/PillarCheckBox';
import Testsite from '../classes/Testsite';
import WeatherColumnCheckBox from '../components/WeatherColumnCheckBox';
import Weathercolumn from '../classes/Weathercolumn';
import EditPillarDialog from '../components/EditPillarDialog';
import EditWeathercolumnDialog from '../components/EditWeathercolumnDialog';
import Constants from '../classes/Constants';

import PlayIcon from '@mui/icons-material/PlayArrow';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import SkipNext from '@mui/icons-material/SkipNext';
import Replay10 from '@mui/icons-material/Replay10';
import Forward from '@mui/icons-material/Forward';
import Forward10 from '@mui/icons-material/Forward10';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

//PDF
import { jsPDF } from "jspdf";
import { ArrowBack } from '@mui/icons-material';


//Chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ScatterController,
  BubbleController,
  Filler,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  ChartDataLabels,
  TimeScale
); 

//Labels für jeden Datenpunkt standardmäßig deaktivieren
/*ChartJS.defaults.set('plugins.datalabels', {labels: {
  title: null
}});*/

//Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoicGVudGFtYXBmbG9yaWFuIiwiYSI6ImNrNm0yMWRxZDBlYXMzbXFzOGJmYW5hc2IifQ.LH1SPlbmgJ82PJh3pdJWnw';

export default function Karte() {

  const mapContainer = useRef(null);
  const mapInfo = useRef(null);
  const map = useRef(null);
  const chartPfeilerRef = useRef("chartPfeilerRef");
  const chartPfeilerDispRadRef = useRef("chartPfeilerDispRadRef");
  const chartPfeilerDispTanRef = useRef("chartPfeilerDispTanRef");
  const chartPfeilerDispTotRef = useRef("chartPfeilerDispTotRef");
  const chartStaumauerRef = useRef("chartStaumauerRef");
  const chartWetterRef = useRef("chartWetterRef");

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  const [lng, setLng] = useState(13.339583);
  const [lat, setLat] = useState(47.079411);
  const [zoom, setZoom] = useState(14);

  //Chart
  const [sliderDate, setSliderDate] = useState("27.4.2022, 13:00:00");
  const [sliderMinDate, setSliderMinDate] = useState();
  const [sliderMaxDate, setSliderMaxDate] = useState();
  const [chartDataWetter, setChartDataWetter] = useState([]);
  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)

  //Messdaten
  const [checkedPillarData, setCheckedPillarData] = useState([])
  const [checkedWeatherData, setCheckedWeatherData] = useState([])
  const [lineChartData, setLineChartData] = useState()
  const [lineChartTime, setLineChartTime] = useState()
  const [radialDisplacementChart, setRadialDisplacementChart] = useState()
  const [tangentialDisplacementChart, setTangentialDisplacementChart] = useState()
  const [totalDisplacementChart, setTotalDisplacementChart] = useState()

  const [editPillar, setEditPillar] = useState()
  const [editPillarDialogVisible, setEditPillarDialogVisible] = useState(false)

  const [editWeathercolumn, setEditWeathercolumn] = useState()
  const [editWeathercolumnDialogVisible, setEditWeathercolumnDialogVisible] = useState(false)

  const staumauerChartOptions = {
    //responsive: false,
    //aspectRatio: 1,
    plugins: {
      title: {
        display: true,
        text: 'Staumauer'
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Y [m]'
        },
        min: -50,
       max: 550,
       ticks: {
        // forces step size to be 50 units
        stepSize: 50
      }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'X [m]'
        },
        min: -50,
        max: 250,
        ticks: {
          // forces step size to be 50 units
          stepSize: 50
        }
      }
    },
    parsing: {
      xAxisKey: 'y',
      yAxisKey: 'x'
    },
    tooltips: {
      callbacks: {
         label: function(tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
         }
      }
   }
  }

  const dcTowerChartOptions = {
    //responsive: false,
    aspectRatio: 1,
    plugins: {
      title: {
        display: true,
        text: 'DC Tower'
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Y [m]'
        },
        min: -15,
        max: 40,
        /*ticks: {
          // forces step size to be 50 units
          stepSize: 50
        }*/
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'X [m]'
        },
        min: -20,
        max: 35,
        /*ticks: {
          // forces step size to be 50 units
          stepSize: 50
        }*/
      }
    },
    parsing: {
      xAxisKey: 'y',
      yAxisKey: 'x'
    },
    tooltips: {
      callbacks: {
         label: function(tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
         }
      }
   }
  }

  //Filter
  const [filter, setFilter] = useState({begin: moment(), end: moment()})
  const [begin, setBegin] = useState(moment())
  const [minBegin, setMinBegin] = useState(moment())
  const [end, setEnd] = useState(moment())
  const [maxEnd, setMaxEnd] = useState(moment())

  //const INITIAL_TESTSITE = { id: Testsite.KOELNBREINSPERRE, name: 'Kölnbreinsperre', center: [13.3394915, 47.0795017], zoom: 14, animation_interval: 24 }
  //const INITIAL_TESTSITE_OPTIONSSTATE = { value: Testsite.KOELNBREINSPERRE, label: 'Kölnbreinsperre' }

  const INITIAL_TESTSITE = { id: Testsite.DCTOWER, name: 'DC Tower', center: [16.4126, 48.23175], zoom: 18, animation_interval: 1 }
  const INITIAL_TESTSITE_OPTIONSSTATE = { value: Testsite.DCTOWER, label: 'DC Tower' }

  //Select
  const [selectTestsiteOptions, setSelectTestsiteOptions] = useState()
  const [testsiteOptionsState, setTestsiteOptionsState] = useState(INITIAL_TESTSITE_OPTIONSSTATE);

  const [selectCampaignOptions, setSelectCampaignOptions] = useState()
  const [campaignOptionsState, setCampaignOptionsState] = useState();

  const [selectWeatherOptions, setSelectWeatherOptions] = useState()
  const [weatherOptionsState, setWeatherOptionsState] = useState({ value: 1, label: 'Stauhöhe' });


  const [testsites, setTestsites] = useState();
  const [testsite, setTestsite] = useState(INITIAL_TESTSITE);

  const [campaigns, setCampaigns] = useState()
  const [campaign, setCampaign] = useState()

  //Staumauer Animation
  //const STAUMAUER_OFFSET = {x: 215554.75, y: 450811.72} //M1
  const STAUMAUER_OFFSET = {x: 215433.40, y: 450315.24} //M5
  const [animationId, setAnimationId] = useState();
  const [animationStep, setAnimationStep] = useState(0)
  const [animationPaused, setAnimationPaused] = useState(false);
  const [animationM1T5Data, setAnimationM1T5Data] = useState()
  const [animationD3Data, setAnimationD3Data] = useState()
  const [staumauerM1T5Mean, setStaumauerM1T5Mean] = useState()
  const [staumauerD3Mean, setStaumauerD3Mean] = useState()
  const [staumauerAnimationM1T5Datasets, setStaumauerAnimationM1T5Datasets] = useState([])
  const [staumauerAnimationD3Datasets, setStaumauerAnimationD3Datasets] = useState([])

  //DC Tower Animation
  //const DCTOWER_OFFSET = {x: 348295.98, y: 678812.74} //M1
  const DCTOWER_OFFSET = {x: 348300.21, y: 678809.04} //M2
  const [animationM1T2Data, setAnimationM1T2Data] = useState()
  const [dcTower, setDCTower] = useState()
  const [dcTowerM1T2Mean, setDCTowerM1T2Mean] = useState()
  const [dcTowerAnimationM1T2Datasets, setDCTowerAnimationM1T2Datasets] = useState([])
  const [windAnimationDatasets, setWindAnimationDatasets] = useState()
  const [windAnimationData, setWindAnimationData] = useState()
  const [weatherWindAnimationData, setWeatherWindAnimationData] = useState()

  useEffect(() => {
    //console.log("Karte. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);

  
  useEffect(() => {

    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: testsite?.center ?? [13.3394915, 47.0795017],
      zoom: testsite?.zoom ?? zoom
    });

    //Warten bis styleURL geladen ist
    map.current.on('load', () => {

      //Add source
      map.current.addSource('pillar-source', {
        type: 'geojson',
        data: {
          type:'FeatureCollection',
          features: []
        }
      });
      
      //Pillar
      map.current.addLayer({
        'id': 'pillar-layer',
        'type': 'circle',
        'source': 'pillar-source',
        'paint': {
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-color': ['get', 'color'],
          'circle-stroke-color': 'white'
        }
      })
      //Pillar Label
      map.current.addLayer({
        'id': 'pillar-layer-label',
        'type': 'symbol',
        'source': 'pillar-source',
        'layout': {
          'text-field': ['get', 'name'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 12
          }
      });

      //Testsites abfragen (Projektid 3 = InfraHealth)
      fetchTestsites(3)

    });

    
    
  },[]);

   /**
   * Fetch testsites for project
   * @param {*} projectId 
   */
   async function fetchTestsites(projectId){
    console.log("fetchTestsites", projectId)

    await fetch(Constants.API_URL + '/testsites/project/'+projectId).then((res) => {
        return res.json();
    }).then(async (testsitesJson) => {
      console.log("Testsites data: ", testsitesJson)
      if(testsitesJson?.length){

        setTestsites(testsitesJson)

        let options = []
        testsitesJson.map(testsite => {
          options.push({value: testsite.id, label: testsite.name})

          //Center
          switch(testsite.id){
            //TÜPL Seetaleralpe
            case Testsite.TUEPLSEETALERALPE:
              testsite.center = [14.586429, 47.104332]
              testsite.zoom = 14
              testsite.animation_interval = 1
              break;
            //Kölnbreinsperre
            case Testsite.KOELNBREINSPERRE:
              testsite.center = [13.3394915, 47.0795017]
              testsite.zoom = 14
              testsite.animation_interval = 24
              break;
            //DC Tower
            case Testsite.DCTOWER:
              testsite.center = [16.4126, 48.23175]
              testsite.zoom = 16
              testsite.animation_interval = 1
              break;
          }

          //Initiale Testsite
          if(testsite.id == INITIAL_TESTSITE.id){
            setTestsite(testsite)
            fetchCampaigns(testsite.id)
          }

        })
        setSelectTestsiteOptions(options)

      }
    })
  }

  /**
   * Fetch campaigns for testsite
   * @param {*} testsiteId 
   */
  async function fetchCampaigns(testsiteId){
    console.log("fetchCampaigns", testsiteId)

    await fetch(Constants.API_URL + '/campaigns/testsite/'+testsiteId).then((res) => {
      return res.json();
    }).then(async (campaignsJson) => {
      console.log("Campaign data: ", campaignsJson)
      if(campaignsJson?.length){

        setCampaigns(campaignsJson)
        setCampaign(campaignsJson[0])

        let options = []
        campaignsJson.map(campaign => {
          options.push({value: campaign.id, label: campaign.name, time_begin: campaign.time_begin, time_end: campaign.time_end, pillars: campaign.pillars, weathercolumns: campaign.weathercolumns})
        })
        setSelectCampaignOptions(options)

        //Erste Kampagne
        if(options?.length){
          setCampaignOptionsState(options[0])
          let begin = options[0].time_begin
          let end = options[0].time_end
          let momentBegin = moment(begin)
          let momentEnd = moment(end)
          setBegin(momentBegin)
          setMinBegin(momentBegin)
          setEnd(momentEnd)
          setMaxEnd(momentEnd)
          filter.begin = momentBegin
          filter.end = momentEnd
          setFilter(filter)

          //Alle Messungen nach Zeitpunkt
          //fetchTimeMeasurements(5, filter)

          //Pillars
          let pillarData = options[0].pillars
          pillarData.map(pillar => {
            pillar.checked = true
          })

          //Wetter
          let weatheroptions = [{value: -1, label: "Zeit", unit: "h"}]
          let weathercolumns = options[0].weathercolumns
          weathercolumns.map(column => {
            column.checked = true
            weatheroptions.push({value: column.id, label: column.name, unit: column.unit})
          })
          setSelectWeatherOptions(weatheroptions)
          setCheckedWeatherData(weathercolumns)

          setWeatherOptionsState(testsiteId == Testsite.KOELNBREINSPERRE ? weatheroptions[0] : weatheroptions[3]);

          let weathercolumn = testsiteId == Testsite.KOELNBREINSPERRE ? weatheroptions[0] : weatheroptions[3]
          fetchDisplacements(testsiteId, pillarData, filter, weathercolumn)

          fetchWeatherdata(testsiteId, filter, weathercolumns)  

          //Daten laden
          fetchMeasurements(testsiteId, pillarData, filter);

          setCheckedPillarData(pillarData)

        }
        
      }else{
        setCampaigns()
        setCampaign()
      }
    })
  }

  /**
   * Fetch weatherdata for testsite
   * @param {*} testsiteId 
   * @param {*} filter 
   */
  async function fetchWeatherdata(testsiteId, filter, weathercolumns){
    console.log("fetchWeatherdata", testsiteId, filter, weathercolumns)

    let query = ""
    if(filter?.begin && filter?.end){
      query = "?begin="+filter.begin + "&end="+filter.end
      console.log("Karte Query: ", query)
    }

    fetch(Constants.API_URL + '/weatherdata/testsite/'+testsiteId+query).then((res) => {
      return res.json();
    }).then(async (weatherdataJson) => {
      console.log("Weather data: ", weatherdataJson)
      handleWeatherdata(weatherdataJson, weathercolumns)
    })
  }

    /**
   * Fetch measurement
   */
    async function fetchMeasurements(testsiteId = 3, pillarData, filter){
      if(testsiteId > 0){

        //let id = pillar.id
      //console.log('fetchMeasurements', id);
        let query = ""
        if(filter?.begin && filter?.end){
          query = "?begin="+filter.begin + "&end="+filter.end
          console.log("Karte Query: ", query)
        }

        await fetch(Constants.API_URL + '/measurements/testsite/'+testsiteId+query).then((res) => {
          //console.log("fetchMeasurements res: ", res)
          return res.json();
        }).then(async (measurementsJson) => {
          console.log("Measurements json: ", measurementsJson)

          /*const {chartData, chartStats, chartOptions} = handleMeasurements(pillar, measurementsJson)
          //console.log("ChartData: ", chartData)
          pillar.chartData = chartData
          pillar.chartStats = chartStats
          pillar.chartOptions = chartOptions*/

          handleMeasurements(pillarData, measurementsJson)

          let pillarFeatures = []

          pillarData.map(async pillar => {

            //console.log("Measurement: ", measurement)
            pillarFeatures.push({
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": [pillar.lon, pillar.lat]
              },
              "properties": {
                "id": pillar.id,
                "name": pillar.name,
                "color": pillar.color
              }
            })

          })

          //Pillars zur Karte hinzufügen
          let data = {
            type:'FeatureCollection',
            features: pillarFeatures
          }

          if(map.current) map.current.getSource('pillar-source').setData(data);

          //Gebäude / Objekt
          switch(testsiteId) {
            case Testsite.KOELNBREINSPERRE:
              drawStaumauer(pillarData);
              break;
            case Testsite.DCTOWER:
              drawDCTower(pillarData);
              break;
          }

        });

      }
    }

/**
 * Fetch Displacements 
 * @param {*} filter 
 */
async function fetchDisplacements(testsiteId = 3, pillars, filter, weathercolumn){
  console.log("fetchDisplacements", weathercolumn)
  let query = ""
  if(filter?.begin && filter?.end){
    query += "?begin="+filter.begin + "&end="+filter.end
  }

  await fetch(Constants.API_URL + '/measurements/movements/'+testsiteId+query).then((res) => {
    //console.log("fetchMeasurements res: ", res)
    return res.json();
  }).then(async (json) => {
    console.log("Displacementsjson: ", json)

    console.log("checkedPillarData", pillars)

    pillars.map(p => {
      p.rad = []
      p.tan = []
      p.tot = []
    })

    //Custom pointStyle
    //const pointImage = new Image()
    //pointImage.src = "logo192.png"
    //pointImage.src = "arrow.png"

    let labels = []

    //Anzahl der Stunden
    let len = json.length
    for(let i=0; i<len; i++){
      let data = json[i]

      //Labels
      labels.push(data.time)

      let weatherdata;
      switch(weathercolumn?.label){
        case 'Zeit':
          weatherdata = moment(data.time)
          break;
        case 'Temperatur':
          weatherdata = data.temperature
          break;
        case 'Niederschlag':
          weatherdata = data.precipitation
          break;
        case 'Luftfeuchtigkeit':
          weatherdata = data.humidity
          break;
        case 'Windstärke':
          weatherdata = data.wind_strength
          break;
        case 'Windrichtung':
          weatherdata = data.wind_direction
          break;
        case 'Strahlung':
          weatherdata = data.radiation
          break;
        case 'Stauhöhe':
          weatherdata = data.water_level
          break;
        default:
          switch(testsiteId){
            case Testsite.KOELNBREINSPERRE:
              weatherdata = data.water_level
              break;
            case Testsite.DCTOWER:
              weatherdata = data.wind_strength
              break;
            default:
              weatherdata = data.time
          }
      }

      //Movements (Anzahl der Pfeiler)
      let sets = data.movements
      for(let j=0; j<sets.length; j++){
        let mov = sets[j]

        let pillars_id = mov.pillars_id
        let rad = mov.rad
        let tan = mov.tan

        pillars.map(p => {
          if(p.id == pillars_id){
            p.rad.push({rad: rad, weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
            p.tan.push({tan: tan, weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
            p.tot.push({total: Math.sqrt(Math.pow(tan,2) + Math.pow(rad,2)), weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
          }
        })
        /*if(pillars_id == 9){
          dataRad.push({rad: rad, weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
          dataTan.push({tan: tan, weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
          dataTotal.push({total: Math.sqrt(Math.pow(tan,2) + Math.pow(rad,2)), weatherdata: weatherdata, wind_strength: data.wind_strength, wind_direction: data.wind_direction})
        }*/
      }
    }

    //Datensatz je Pfeiler
    let datasetsRad = []
    let datasetsTan = []
    let datasetsTotal = []

    let pointStyle = testsiteId == Testsite.DCTOWER ? 'triangle' : 'point'

    //Anzahl der Pfeiler
    pillars.map(pillar => {

      let label = pillar.name
      let color = pillar.color
      let dataRad = pillar.rad
      let dataTan = pillar.tan
      let dataTotal = pillar.tot

      let pointRadiusRad = testsiteId == Testsite.DCTOWER ? dataRad?.map(datapoint => {
        return datapoint.wind_strength
      }) : 1
      let pointRotationRad = testsiteId == Testsite.DCTOWER ? dataRad?.map(datapoint => {
        return datapoint.wind_direction
      }) : 0

      let pointRadiusTan = testsiteId == Testsite.DCTOWER ? dataTan?.map(datapoint => {
        return datapoint.wind_strength
      }) : 1
      let pointRotationTan = testsiteId == Testsite.DCTOWER ? dataTan?.map(datapoint => {
        return datapoint.wind_direction
      }) : 0

      let pointRadiusTotal = testsiteId == Testsite.DCTOWER ? dataTotal?.map(datapoint => {
        return datapoint.wind_strength
      }) : 1
      let pointRotationTotal = testsiteId == Testsite.DCTOWER ? dataTotal?.map(datapoint => {
        return datapoint.wind_direction
      }) : 0

      let datasetRad = {
        label: label,
        labels: labels,
        datalabels: {labels: {title: null}} ,
        data: dataRad,
        pointStyle: pointStyle,
        pointRadius: pointRadiusRad,
        rotation: pointRotationRad,
        borderColor: color,
        backgroundColor: color,
        showLine: false
      }

      let datasetTan = {
        label: label,
        labels: labels,
        datalabels: {labels: {title: null}} ,
        data: dataTan,
        pointStyle: pointStyle,
        pointRadius: pointRadiusTan,
        rotation: pointRotationTan,
        borderColor: color,
        backgroundColor: color,
        showLine: false
      }

      let datasetTotal = {
        label: label,
        datalabels: {labels: {title: null}} ,
        data: dataTotal,
        pointStyle: pointStyle,
        pointRadius: pointRadiusTotal,
        rotation: pointRotationTotal,
        borderColor: color,
        backgroundColor: color,
        showLine: false
      }

      datasetsRad.push(datasetRad)
      datasetsTan.push(datasetTan)
      datasetsTotal.push(datasetTotal)

      //Pfeile linien
      if(testsiteId == Testsite.DCTOWER){

        let datasetRadLine = {
          label: label,
          labels: labels,
          datalabels: {labels: {title: null}} ,
          data: dataRad,
          pointStyle:'line',
          pointRadius: pointRadiusRad,
          rotation: dataRad?.map(datapoint => {
            return datapoint.wind_direction + 90
          }),
          borderColor: color,
          backgroundColor: color,
          showLine: false
        }

        let datasetTanLine = {
          label: label,
          labels: labels,
          datalabels: {labels: {title: null}} ,
          data: dataTan,
          pointStyle:'line',
          pointRadius: pointRadiusTan,
          rotation: dataTan?.map(datapoint => {
            return datapoint.wind_direction + 90
          }),
          borderColor: color,
          backgroundColor: color,
          showLine: false
        }

        let datasetTotalLine = {
          label: label,
          datalabels: {labels: {title: null}} ,
          data: dataTotal,
          pointStyle:'line',
          pointRadius: pointRadiusTotal,
          rotation: dataTotal?.map(datapoint => {
            return datapoint.wind_direction + 90
          }),
          borderColor: color,
          backgroundColor: color,
          showLine: false
        }

        datasetsRad.push(datasetRadLine)
        datasetsTan.push(datasetTanLine)
        datasetsTotal.push(datasetTotalLine)
      }
   
    })
      

    let radialDisplacementChartData = {
      //labels: labels,
      datasets: datasetsRad,
    };

    let radialDisplacementChartOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Radial displacement'
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          type: weathercolumn.label == "Zeit" ? 'time': "linear",
          max: weathercolumn.label == "Zeit" ? moment("2022-11-16T17:00:00.000Z"): null,
          display: true,
          title: {
            display: true,
            text: weathercolumn ? weathercolumn.label + ' [' +weathercolumn.unit + ']' : 'Stauhöhe [m]'
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Radial displacement [mm]'
          }
        }
      },
      parsing: {
        xAxisKey: 'weatherdata',
        yAxisKey: 'rad'
      },
      tooltips: {
        callbacks: {
           label: function(tooltipItem, data) {
              var label = data.labels[tooltipItem.index];
              return label + ': (' + tooltipItem.xLabel + ' '+ weathercolumn.unit+ ', ' + tooltipItem.yLabel + '[mm])';
              //return "Sepp"
           }
        }
     }
    }
    setRadialDisplacementChart({data:radialDisplacementChartData, options: radialDisplacementChartOptions})


    let tangentialDisplacementChartData = {
      //labels: labels,
      datasets: datasetsTan,
    };

    let tangentialDisplacementChartOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Tangential displacement'
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
      interaction: {
        intersect: false,
      }, 
      scales: {
        x: {
          type: weathercolumn.label == "Zeit" ? 'time': "linear",
          max: weathercolumn.label == "Zeit" ? moment("2022-11-16T17:00:00.000Z"): null,
          display: true,
          title: {
            display: true,
            text: weathercolumn ? weathercolumn.label + ' [' +weathercolumn.unit + ']' : 'Stauhöhe [m]'
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Tangential displacement [mm]'
          }
        }
      },
      parsing: {
        xAxisKey: 'weatherdata',
        yAxisKey: 'tan'
      },
      tooltips: {
        callbacks: {
           label: function(tooltipItem, data) {
              var label = data.labels[tooltipItem.index];
              return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
           }
        }
     }
    }

    setTangentialDisplacementChart({data:tangentialDisplacementChartData, options: tangentialDisplacementChartOptions})

    let totalDisplacementChartData = {
      //labels: labels,
      datasets: datasetsTotal,
    };

    let totalDisplacementChartOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Total displacement'
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          type: weathercolumn.label == "Zeit" ? 'time': "linear",
          max: weathercolumn.label == "Zeit" ? moment("2022-11-16T17:00:00.000Z"): null,
          display: true,
          title: {
            display: true,
            text: weathercolumn ? weathercolumn.label + ' [' +weathercolumn.unit + ']' : 'Stauhöhe [m]'
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Total displacement [mm]'
          }
        }
      },
      parsing: {
        xAxisKey: 'weatherdata',
        yAxisKey: 'total'
      },
      tooltips: {
        callbacks: {
           label: function(tooltipItem, data) {
              var label = data.labels[tooltipItem.index];
              return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
           }
        }
     }
    }

    setTotalDisplacementChart({data:totalDisplacementChartData, options: totalDisplacementChartOptions})

  });
}

  /**
 * Fetch time measurements
 */
  async function fetchTimeMeasurements(referencePillarId, filter){

    console.log('fetchTimeMeasurements', referencePillarId);
      let query = ""
      if(filter?.begin && filter?.end){
        query += "?begin="+filter.begin + "&end="+filter.end
        console.log("Karte Query: ", query)
      }
  
      await fetch(Constants.API_URL + '/measurements/time/'+referencePillarId+query).then((res) => {
        //console.log("fetchMeasurements res: ", res)
        return res.json();
      }).then(async (measurementsJson) => {
        console.log("Time Measurements json: ", measurementsJson)
  
      });
  
    }

  /**
   * Wetterdaten
   * @param {*} column 
   * @param {*} dataArray 
   */
  function getWeatherDataset(column, dataArray, labels){
    
    return {
      labels,
      datasets: [
        {
          label: column.name,
          datalabels: {labels: {title: null}} ,
          data: dataArray.map(datapoint => {return datapoint.value}),
          //borderColor: 'rgb(211, 211, 211)',
          backgroundColor: 'rgb(211, 211, 211)',
          pointColor: dataArray.map(datapoint => {
            let color = 'rgb(155, 199, 132)'
            if(datapoint.value > column.upper_limit) color = 'rgb(255, 99, 132)'
            if(datapoint.value < column.lower_limit) color = 'rgb(53, 162, 235)'
            return color
          }),
          pointBackgroundColor: dataArray.map(datapoint => {
            let color = 'rgb(155, 199, 132)'
            if(datapoint.value > column.upper_limit) color = 'rgb(255, 99, 132)'
            if(datapoint.value < column.lower_limit) color = 'rgb(53, 162, 235)'
            return color
          }),
        },
        {
          label: 'u. Grenze',
          datalabels: {labels: {title: null}} ,
          //data: dataArray.map(datapoint => {return column.lower_limit}),
          data: JSON.parse(JSON.stringify(dataArray)).fill(column.lower_limit),
          borderColor: 'rgba(155, 199, 132)',
          backgroundColor: 'rgba(155, 199, 132, 0.5)',
        },
        {
          label: 'o. Grenze',
          datalabels: {labels: {title: null}} ,
          //data: dataArray.map(datapoint => {return column.upper_limit}),
          data: JSON.parse(JSON.stringify(dataArray)).fill(column.upper_limit),
          borderColor: 'rgba(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ],
    }
  }

/**
 * WetterdatenJSON auslesen
 * @param {*} result 
 */
function handleWeatherdata(result, weathercolumns){
  console.log("handleWeatherdata", weathercolumns)

  let labels = []
  //let weatherArray = []
  let temperaturArray = []
  let niederschlagArray = []
  let luftfeuchteArray = []
  let windArray = []
  let windrichtungArray = []
  let strahlungArray = []
  let stauhoeheArray = []
  let temp2Array = []
  let luftdruckArray = []
  let taupunktArray = []

  let windAnimationData = []

  let len = result.length
  for(let i=1; i<len; i++){
    let wetter = result[i]
    let datum = new Date(wetter.date)

    //Datum formatieren
    let day = datum.getDate();
    let month = datum.getMonth() + 1;
    //let year = datum.getFullYear();
    let hours = datum.getHours();
    let minutes = datum.getMinutes();

    let datumFormat = day + "." + month  + ". " + hours + ":00"// + year

    labels.push(datumFormat)
    temperaturArray.push({i: i, datum: datum, value: wetter.temperature})
    niederschlagArray.push({i: i, datum: datum, value: wetter.precipitation})
    luftfeuchteArray.push({i: i, datum: datum, value: wetter.humidity})
    windArray.push({i: i, datum: datum, value: wetter.wind_strength})
    windrichtungArray.push({i: i, datum: datum, value: wetter.wind_direction})
    strahlungArray.push({i: i, datum: datum, value: wetter.radiation})
    stauhoeheArray.push({i: i, datum: datum, value: wetter.water_level})
    temp2Array.push({i: i, datum: datum, value: wetter.temperature_alternative})
    luftdruckArray.push({i: i, datum: datum, value: wetter.airpressure})
    taupunktArray.push({i: i, datum: datum, value: wetter.dewpoint})

    //Wetterdaten (Windrichtung)
    //let windrichtung = checkedWeatherData[3]?.chartData
    //console.log("Windrichtung: ", windrichtungArray, Math.sin(wetter.wind_direction), Math.cos(wetter.wind_direction))
    //console.log("Windstärke: ", wetter.wind_strength)

    //Mittelpunkt (0/5)
    //Radius 10

    let pwr = {
        x: 0 + 10 * Math.cos(Math.PI * wetter.wind_direction / 180),
        y: 5 + 10 * Math.sin(Math.PI * wetter.wind_direction / 180),
        rotation: wetter.wind_direction - 180,
        label: wetter.wind_direction+"° "
      }

    windAnimationData.push([
      //Windrichtung in Grad
      pwr,
      //Windstärke
      {
        x: pwr.x + wetter.wind_strength * Math.cos(Math.PI * wetter.wind_direction / 180),
        y: pwr.y + wetter.wind_strength * Math.sin(Math.PI * wetter.wind_direction / 180),
        rotation: wetter.wind_direction - 180,
        label: wetter.wind_strength+"km/h"
      }]
    )

  }

  setWindAnimationDatasets(windAnimationData)
  setWindAnimationData(windAnimationData[0])

    

  //Wetterspalten
  //let chartData = []
  weathercolumns.map(column => {
    column.chartData = []
    if(column.checked){
      switch(column.id){
        case Weathercolumn.TEMPERATURE:
          //chartData.push(getWeatherDataset(column, temperaturArray, labels))
          column.chartData = getWeatherDataset(column, temperaturArray, labels)
          break;
        case Weathercolumn.NIEDERSCHLAG:
          //chartData.push(getWeatherDataset(column, niederschlagArray, labels))
          column.chartData = getWeatherDataset(column, niederschlagArray, labels)
          break;
        case Weathercolumn.LUFTFEUCHTIGKEIT:
          //chartData.push(getWeatherDataset(column, luftfeuchteArray, labels))
          column.chartData = getWeatherDataset(column, luftfeuchteArray, labels)
          break;
        case Weathercolumn.WINDSTAERKE:
          //chartData.push(getWeatherDataset(column, windArray, labels))
          column.chartData = getWeatherDataset(column, windArray, labels)
          break;
        case Weathercolumn.WINDRICHTUNG:
         // chartData.push(getWeatherDataset(column, windrichtungArray, labels))
         column.chartData = getWeatherDataset(column, windrichtungArray, labels)
         break;
        case Weathercolumn.STRAHLUNG:
          //chartData.push(getWeatherDataset(column, strahlungArray, labels))
          column.chartData = getWeatherDataset(column, strahlungArray, labels)
          break;
        case Weathercolumn.STAUHOEHE:
          //chartData.push(getWeatherDataset(column, stauhoeheArray, labels))
          column.chartData = getWeatherDataset(column, stauhoeheArray, labels)
          break;
        case Weathercolumn.LUFTDRUCK:
          //chartData.push(getWeatherDataset(column, luftdruckArray, labels))
          column.chartData = getWeatherDataset(column, luftdruckArray, labels)
          break;
        case Weathercolumn.TAUPUNKT:
          //chartData.push(getWeatherDataset(column, taupunktArray, labels))
          column.chartData = getWeatherDataset(column, taupunktArray, labels)
          break;
      }
    }
    
  })

  //setCheckedWeatherData(weathercolumns)

  /*return {
    chartData:chartData
  }*/
}

/**
 * 1760 bis 1902 (142 Meter)
 * @param {*} waterLevel 
 */
function getWaterLevelColor(waterLevel) {
  if(waterLevel > 1890) return 'rgba(165,0,38,1)';
  if(waterLevel > 1880) return 'rgba(215,48,39,1)';
  if(waterLevel > 1870) return 'rgba(244,109,67,1)';
  if(waterLevel > 1860) return 'rgba(253,174,97,1)';
  if(waterLevel > 1850) return 'rgba(254,224,139,1)';
  if(waterLevel > 1840) return 'rgba(255,255,191,1)';
  if(waterLevel > 1830) return 'rgba(217,239,139,1)';
  if(waterLevel > 1820) return 'rgba(166,217,106,1)';
  if(waterLevel > 1810) return 'rgba(102,189,99,1)';
  if(waterLevel > 1800) return 'rgba(26,152,80,1)';
  if(waterLevel > 1790) return 'rgba(0,104,55,1)';
  return 'rgba(0,104,55,1)';

}

 /**
 * EditPillar
 * @param {*} item 
 */
 const showEditPillarDialog = (item) => {
  console.log("showEditPillarDialog: ", item)
  setEditPillar(item)
  setEditPillarDialogVisible(true)      
}

/** 
 * SavePillar
*/
const savePillar = (pillar) => {
  console.log("savePillar", pillar)

  //Updaten
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: pillar.id,
      name: pillar.name,
      color: pillar.color,
      angle_rad_displacement: pillar.angle_rad_displacement
    })
  };

  fetch(Constants.API_URL + '/pillars/'+pillar.id, requestOptions).then((res) => {
      return res.json();
  }).then(async (json) => {
    console.log(json);
  });
}

 /**
 * EditPillar
 * @param {*} item 
 */
 const showEditWeathercolumnDialog = (item) => {
  console.log("showEditWeathercolumnDialog: ", item)
  setEditWeathercolumn(item)
  setEditWeathercolumnDialogVisible(true)      
}

/** 
 * SaveWeathercolumn
*/
const saveWeathercolumn = (column) => {
  console.log("saveWeathercolumn", column)
  //Weathercolumn hat sich geändert z.B. Winkel, Checked State etc.

    //Updaten
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: column.id,
        lower_limit: column.lower_limit,
        upper_limit: column.upper_limit
      })
    };

    fetch(Constants.API_URL + '/weathercolumns/'+testsite.id +'/'+ column.id, requestOptions).then((res) => {
        return res.json();
    }).then(async (json) => {
      console.log(json);
    });


}

/**
 * Messungen verarbeiten
 * @param {*} pillarData 
 * @param {*} result 
 * @returns 
 */
function handleMeasurements(pillars, result) {
  console.log("handleMeasurements")

  let avg_x = 0.0
  let avg_y = 0.0
  let last_x = 0.0
  let last_y = 0.0
  let min_x = Infinity
  let max_x = -Infinity
  let min_y = Infinity
  let max_y = -Infinity

  let len = result.length;

  //Pfeiler
  pillars.map(p => {
    p.data = new Array(len)
    p.avg_x = avg_x
    p.avg_y = avg_y
    p.last_x = last_x
    p.last_y = last_y
  })

  //Stunden
  for(let i=0; i<len; i++){
    let data = result[i];
    //console.log("data", data)

    let time = data.time

    //Erster Punkt
    if(i==0){
      let momentBegin = moment(time)
      setSliderMinDate(time)
      setBegin(momentBegin)
      setMinBegin(momentBegin)
    }

    //Letzter Punkt
    if(i==len-1){
      let momentEnd = moment(time)
      setSliderMaxDate(time)
      setEnd(momentEnd)
      setMaxEnd(momentEnd)
    }

    //Messungen (Anzahl der Pfeiler)
    let sets = data.measurements
    let len_sets = sets.length
    //Falls Messung fehlt => letzte Messung noch einmal setzen
    if(i > 0 && len_sets < pillars.length){
      console.log("Pillars Sets: ", time, sets)
      
      pillars.map(p => {
        let new_data = p.data[i-1]
        new_data.i = i
        p.data[i] = new_data
      })

    }

    for(let j=0; j<len_sets; j++){
      let data = sets[j]

      let pillars_id = data.pillars_id
      let x = data.lon_bmn
      let y = data.lat_bmn

      if(!isNaN(x) && !isNaN(y)){
        pillars.map(p => {
          if(p.id == pillars_id){

            if(i == 0){
              p.avg_x = x
              p.avg_y = y
            }

            if(i == len-1){
              p.last_x = x
              p.last_y = y
            }

            //Differenzen in mm
            x = (x - p.avg_x) * 1000
            y = (y - p.avg_y) * 1000
            min_x = x < min_x ? x : min_x
            max_x = x > max_x ? x : max_x
            min_y = y < min_y ? y : min_y
            max_y = y > max_y ? y : max_y

            /*p.data.push({
              i:i+1,
              time: time,
              x: x,
              y: y,
              //weatherdata: pfeiler.weatherdata,
              r: len-i < 10 ? 2+10-(len-i) : 2
            })*/

            p.data[i] = {
              i:i+1,
              time: time,
              x: x,
              y: y,
              r: len-i < 10 ? 2+10-(len-i) : 2
            }

          }
        })
      }

    }

  }

  max_x = Math.ceil(Math.abs(min_x) > max_x ? Math.abs(min_x) : max_x)
  max_y = Math.ceil(Math.abs(min_y) > max_y ? Math.abs(min_y) : max_y)

  //Globale Achsenskalierung (für alle Messpfeiler gleich)
  if(max_x > maxX) setMaxX(max_x + 10)
  if(max_y > maxY) setMaxY(max_y + 10)

  //Anzahl der Pfeiler
  pillars.map(pillar => {

    let data = pillar.data
    let avg_x = pillar.avg_x
    let avg_y = pillar.avg_y
    let last_x = pillar.last_x
    let last_y = pillar.last_y

    console.log("Pillars Data: ", data.length, data)

    //ChartData
    pillar.chartData = {
      datasets: [
        {
          label: "Messungen",
          datalabels: {labels: {title: null}} ,
          data: data,//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
          //backgroundColor: 'rgba(240, 240, 240, 1)'
          backgroundColor: data.map(datapoint => {
            if(len-datapoint.i < 10){
              return 'rgba(255, 0, 70, 1)'
            }
            //return 'rgba(240, 240, 240, 1)'
            return getWaterLevelColor(datapoint.weatherdata)
          })
        },
        {
          label: "letzte 10 Messungen",
          datalabels: {labels: {title: null}} ,
          data: [],
          backgroundColor: 'rgba(255, 0, 70, 1)'
        },
        {
          label: pillar.name,
          datalabels: {labels: {title: null}} ,
          data: [{
            x: 0,
            y: 0,
            r: 40
          }],
          //backgroundColor: 'rgba(60, 255, 0, 1)'
          backgroundColor: pillar.color
        }
      
      ],
    }

    //ChartStats
    pillar.chartStats = {avg_x: avg_x.toFixed(2), avg_y: avg_y.toFixed(2), last_x: last_x.toFixed(2), last_y: last_y.toFixed(2)}

    //ChartOptions
    pillar.chartOptions = {
      plugins: {
        title: {
          display: true,
          text: pillar.name + " (GK: y="+avg_x.toFixed(2) + "m; x=" + avg_y.toFixed(2) + "m)"
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'xy'
          }
        }
      },
      responsive: true,
      scales: {
        y: {
          //beginAtZero: true,
          title: {
            display: true,
            text: 'mittlere Abweichung Hochwert [mm]'
          },
          min: -maxY,
          max: maxY
        },
        x: {
            //beginAtZero: true,
            title: {
              display: true,
              text: 'mittlere Abweichung Rechtswert [mm]'
            },
            min: -maxX,
            max: maxX
          },
      }
    }
  
  })

  setCheckedPillarData([...pillars])


  /*let len = result.length
  for(let i=1; i<len; i++){
    let pfeiler = result[i]
    let time = pfeiler.time

    let x = pfeiler.lon_bmn
    //sum_x += x
    let y = pfeiler.lat_bmn
    //sum_y += y

    //Erster Punkt
    if(i==1){
      let momentBegin = moment(time)
      setSliderMinDate(time)
      setBegin(momentBegin)
      setMinBegin(momentBegin)
      //Bezugswert erster Punkt
      avg_x = x
      avg_y = y
    }

    //Letzter Punkt
    if(i==len-1){
      let momentEnd = moment(time)
      setSliderMaxDate(time)
      setEnd(momentEnd)
      setMaxEnd(momentEnd)
      last_x = x
      last_y = y
    }

    //Differenzen in mm
    x = (x - avg_x) * 1000
    y = (y - avg_y) * 1000
    min_x = x < min_x ? x : min_x
    max_x = x > max_x ? x : max_x
    min_y = y < min_y ? y : min_y
    max_y = y > max_y ? y : max_y
    
    if(!isNaN(x) && !isNaN(y)){
      data.push({
        i:i,
        time: pfeiler.time,
        x: x,
        y: y,
        weatherdata: pfeiler.weatherdata,
        r: len-i < 10 ? 2+10-(len-i) : 2
      })
    }   
  }

  console.log("Minx", min_x, "Miny", min_y, "Maxx", max_x, "Maxy", max_y)

  max_x = Math.ceil(Math.abs(min_x) > max_x ? Math.abs(min_x) : max_x)
  max_y = Math.ceil(Math.abs(min_y) > max_y ? Math.abs(min_y) : max_y)

  //Globale Achsenskalierung (für alle Messpfeiler gleich)
  if(max_x > maxX) setMaxX(max_x + 10)
  if(max_y > maxY) setMaxY(max_y + 10)

  //Chart Daten
  const chartData = {
    datasets: [
      {
        label: "Messungen",
        datalabels: {labels: {title: null}} ,
        data: data,//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        //backgroundColor: 'rgba(240, 240, 240, 1)'
        backgroundColor: data.map(datapoint => {
          if(len-datapoint.i < 10){
            return 'rgba(255, 0, 70, 1)'
          }
          //return 'rgba(240, 240, 240, 1)'
          return getWaterLevelColor(datapoint.weatherdata)
        })
      },
      {
        label: "letzte 10 Messungen",
        datalabels: {labels: {title: null}} ,
        data: [],
        backgroundColor: 'rgba(255, 0, 70, 1)'
      },
      {
        label: pillar.name,
        datalabels: {labels: {title: null}} ,
        data: [{
          x: 0,
          y: 0,
          r: 40
        }],
        //backgroundColor: 'rgba(60, 255, 0, 1)'
        backgroundColor: pillar.color
      }
    
    ],
  };*/

  /*return {
    chartData:chartData, 
    chartStats: {avg_x: avg_x.toFixed(2), avg_y: avg_y.toFixed(2), last_x: last_x.toFixed(2), last_y: last_y.toFixed(2)},
    chartOptions: {
      plugins: {
        title: {
          display: true,
          text: pillar.name + " (GK: y="+avg_x.toFixed(2) + "m; x=" + avg_y.toFixed(2) + "m)"
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'xy'
          }
        }
      },
      responsive: true,
      scales: {
        y: {
          //beginAtZero: true,
          title: {
            display: true,
            text: 'mittlere Abweichung Hochwert [mm]'
          },
          min: -maxY,
          max: maxY
        },
        x: {
            //beginAtZero: true,
            title: {
              display: true,
              text: 'mittlere Abweichung Rechtswert [mm]'
            },
            min: -maxX,
            max: maxX
          },
      }
    }
  }*/
}

/**
 * Pillar Chart Options
 * @param {*} pillar 
 */
const pillarChartOptions = (pillar) => {
  return {
    plugins: {
      title: {
        display: true,
        text: pillar.name + " (GK: y="+pillar?.chartStats.avg_x + "m; x=" + pillar?.chartStats.avg_y + "m)"
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      }
    },
    responsive: true,
    scales: {
      y: {
        //beginAtZero: true,
        title: {
          display: true,
          text: 'mittlere Abweichung Hochwert [mm]'
        },
        min: -maxY,
        max: maxY
      },
      x: {
          //beginAtZero: true,
          title: {
            display: true,
            text: 'mittlere Abweichung Rechtswert [mm]'
          },
          min: -maxX,
          max: maxX
        },
    }
  }
}

const weatherColumnChartOptions = (column) => {
  return {
    plugins: {
      title: {
        display: true,
        text: column.name
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      },
    },
    responsive: true,
    scales: {
      y: {
        //beginAtZero: true,
        title: {
          display: true,
          text: column.name + " [" + column.unit + "]"
        },
      },
      x: {
          //beginAtZero: true,
          title: {
            display: true,
            text: 'Datum'
          },
        },
    }
  }
}
  
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });
  });

  const onChangeDateSlider = (e) => {
    console.log("onChangeDateSlider: ", e.target.value);

    let diff = e.target.value

    console.log("SliderMinDate: ", sliderMinDate)
    let begin = new Date("2022-04-27 13:00");
    //begin.setFullYear(2022,0,1)

    // add one hour
    let end = begin;
    end.setHours(end.getHours() + diff);

    //console.log("EndDate: ", end);
    setSliderDate(end.toLocaleString())


    //Chart color
    //let chartDataPfeilerNew = {...chartDataPfeiler}
    /*chartDataPfeiler.datasets[0].backgroundColor = "rgba(0, 255, 70, 1)";
    console.log("New Color 0: ", chartDataPfeiler.datasets[1])
    console.log("New Color 1: ", chartDataPfeiler.datasets[1].backgroundColor)
    setChartDataPfeiler(chartDataPfeiler)

    chartPfeilerRef.current.update()*/

    //console.log("WetterChart Dataset: ", chartDataWetter.datasets[0])
    //console.log("WetterChart Data: ", chartDataWetter.datasets[0].data[0])

    let data = chartDataWetter.datasets[0].data
    //console.log("Data: ", data)

    chartDataWetter.datasets[0].pointColor = data.map(datapoint => {
      //if(datapoint.i < 100) return "rgba(0, 255, 70, 1)"
      if(datapoint < 5) return "rgba(0, 255, 70, 1)"
    })
    chartDataWetter.datasets[0].pointBackgroundColor = data.map(datapoint => {
      //if(datapoint.i < 100) return "rgba(0, 255, 70, 1)"
      if(datapoint < 5) return "rgba(0, 255, 70, 1)"
    })
    




    //chartDataWetter.datasets[0].pointBackgroundColor = "rgba(0, 255, 70, 1)"
    setChartDataWetter(chartDataWetter)

    chartWetterRef.current.update()
  }

  const onChangeWeatherColumn = (column) => {
    console.log("onChangeWeatherColumn ", column)

    const value = column.id
    const checked = column.checked

    let weatherData = checkedWeatherData

    if(checked) {
      campaign?.weathercolumns.map(column =>{
        if(column.id == value){
          column.checked = checked
          weatherData.push(column)
        }
      })
    }else{
      //Remove
      weatherData = checkedWeatherData.filter((data) => { return data.id != value})
    }

    console.log("checkedWeatherData: ", weatherData)
    setCheckedWeatherData([...weatherData])

    fetchWeatherdata(testsite?.id, filter, weatherData)

  }

  /**
   * Handle change of pillar data
   * @param {*} pillar 
   */
  const onChangePillar = (pillar) => {
    console.log("onChangePillar ", pillar)

    //Pillar hat sich geändert z.B. Winkel, Checked State etc.

    const value = pillar.id
    const checked = pillar.checked

    let pillarData = checkedPillarData

    if(checked) {
      campaign?.pillars.map(pillar =>{
        if(pillar.id == value){
          pillar.checked = checked
          pillarData.push(pillar)
        }
      })
    }else{
      //Remove
      pillarData = checkedPillarData.filter((data) => { return data.id != value})
    }

    console.log("checkedPillarData: ", pillarData)
    setCheckedPillarData([...pillarData])

    //Daten laden
    fetchMeasurements(testsite?.id, pillarData, filter)

    /*let pillarFeatures = []

    pillarData.map(pillar => {
      fetchMeasurements(pillar, filter)

      //console.log("Measurement: ", measurement)
      pillarFeatures.push({
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [pillar.lon, pillar.lat]
        },
        "properties": {
          "id": pillar.id,
          "name": pillar.name,
          "color": pillar.color
        }
      })

    })

    //Pillars zur Karte hinzufügen
    let data = {
      type:'FeatureCollection',
      features: pillarFeatures
    }

    if(map.current) map.current.getSource('pillar-source').setData(data);*/

    //Charts mit Bewegungen
    fetchDisplacements(testsite?.id, checkedPillarData, filter, weatherOptionsState)

    //Chart mit allen Pillars
    //fetchTimeMeasurements(5, filter)

  }

  const playAnimation = () => {
    let length = testsite?.id == Testsite.KOELNBREINSPERRE ? staumauerAnimationM1T5Datasets.length : dcTowerAnimationM1T2Datasets.length
    console.log("playAnimation", length)
    clearInterval(animationId);
    setAnimationPaused(false)

    let i = animationStep;
    let id = setInterval(animation, 100);
    function animation() {
      console.log("animation", i)
      console.log("animationPaused", animationPaused)
      i+= testsite?.animation_interval; //Stunden = 1 Tag

      if (i >= length) {
        clearInterval(id);
        if(testsite?.id == Testsite.KOELNBREINSPERRE){
          let data = staumauerAnimationM1T5Datasets[0].data
          let time = staumauerAnimationM1T5Datasets[0].time
          setAnimationM1T5Data({time: time, data:data})
  
          let dataD3 = staumauerAnimationD3Datasets[0].data
          let timeD3 = staumauerAnimationD3Datasets[0].time
          setAnimationD3Data({time: timeD3, data:dataD3})
        }else{
          //DC Tower
          let data = dcTowerAnimationM1T2Datasets[0].data
          let time = dcTowerAnimationM1T2Datasets[0].time
          setAnimationM1T2Data({time: time, data:data})

          let windData = windAnimationDatasets[0]
          setWindAnimationData(windData)

          //let weatherWindData = checkedWeatherData[2]?.chartData;
          //setWeatherWindAnimationData(weatherWindData)
        }

        setAnimationStep(0)
      } else {
        setAnimationStep(i);

        if(testsite?.id == Testsite.KOELNBREINSPERRE){
          //M1T5
          let data = staumauerAnimationM1T5Datasets[i].data
          let time = staumauerAnimationM1T5Datasets[i].time
          setAnimationM1T5Data({time: time, data:data})

          //D3
          let dataD3 = staumauerAnimationD3Datasets[i].data
          let timeD3 = staumauerAnimationD3Datasets[i].time
          setAnimationD3Data({time: timeD3, data:dataD3})
        }else{
          //DC Tower
          let data = dcTowerAnimationM1T2Datasets[i].data
          let time = dcTowerAnimationM1T2Datasets[i].time
          setAnimationM1T2Data({time: time, data:data})

          let windData = windAnimationDatasets[i]
          setWindAnimationData(windData)

          /*let weatherWindData = checkedWeatherData[2]?.chartData?.datasets[0].data;
          let weatherWindLabels = checkedWeatherData[2]?.chartData?.labels;
          console.log("weatherWindData", {label: weatherWindLabels[i], value:weatherWindData[i]})
          setWeatherWindAnimationData(weatherWindData[i])*/
        }

        chartStaumauerRef.current.update()
      }
      
    }
    setAnimationId(id);
  }

  const replayAnimation = (seconds) => {
    console.log("replayAnimation", animationId)
    let step = animationStep - seconds * testsite?.animation_interval
    if(step < 0) step = 0
    console.log("animation", step)
    setAnimationStep(step)

    if(testsite?.id == Testsite.KOELNBREINSPERRE){
      //M1T5
      let data = staumauerAnimationM1T5Datasets[step].data
      let time = staumauerAnimationM1T5Datasets[step].time
      setAnimationM1T5Data({time: time, data:data})

      //D3
      let dataD3 = staumauerAnimationD3Datasets[step].data
      let timeD3 = staumauerAnimationD3Datasets[step].time
      setAnimationD3Data({time: timeD3, data:dataD3})
    }else{
      //DC Tower
      let data = dcTowerAnimationM1T2Datasets[step].data
      let time = dcTowerAnimationM1T2Datasets[step].time
      setAnimationM1T2Data({time: time, data:data})

      let windData = windAnimationDatasets[step]
      setWindAnimationData(windData)
    }

  }

  const forwardAnimation = (seconds) => {
    let step = animationStep + seconds * testsite?.animation_interval

    let length = testsite?.id == Testsite.KOELNBREINSPERRE ? staumauerAnimationM1T5Datasets.length : dcTowerAnimationM1T2Datasets.length

    if(step > length) step = length - 1
    console.log("animation", step)

    setAnimationStep(step)

    if(testsite?.id == Testsite.KOELNBREINSPERRE){
      //M1T5
      let data = staumauerAnimationM1T5Datasets[step].data
      let time = staumauerAnimationM1T5Datasets[step].time
      setAnimationM1T5Data({time: time, data:data})

      //D3
      let dataD3 = staumauerAnimationD3Datasets[step].data
      let timeD3 = staumauerAnimationD3Datasets[step].time
      setAnimationD3Data({time: timeD3, data:dataD3})
    }else{
      //DC Tower
      let data = dcTowerAnimationM1T2Datasets[step].data
      let time = dcTowerAnimationM1T2Datasets[step].time
      setAnimationM1T2Data({time: time, data:data})

      let windData = windAnimationDatasets[step]
      setWindAnimationData(windData)
    }
  }

  const pauseAnimation = () => {
    console.log("pauseAnimation", animationId)
    setAnimationPaused(true)
    clearInterval(animationId)
  }

  const stopAnimation = () => {
    console.log("stopAnimation", animationId)
    setAnimationStep(0)
    setAnimationPaused(false)

    if(testsite?.id == Testsite.KOELNBREINSPERRE){
      //M1T5
      let data = staumauerAnimationM1T5Datasets[0].data
      let time = staumauerAnimationM1T5Datasets[0].time
      setAnimationM1T5Data({time: time, data:data})

      //D3
      let dataD3 = staumauerAnimationD3Datasets[0].data
      let timeD3 = staumauerAnimationD3Datasets[0].time
      setAnimationD3Data({time: timeD3, data:dataD3})
    }else{
      //DC Tower
      let data = dcTowerAnimationM1T2Datasets[0].data
      let time = dcTowerAnimationM1T2Datasets[0].time
      setAnimationM1T2Data({time: time, data:data})

      let windData = windAnimationDatasets[0]
      setWindAnimationData(windData)
    }

    clearInterval(animationId)
  }

  /**
   * Chart DC Tower
   * @param {*} pillarData 
   */
  const drawDCTower = (pillarData) => {
    console.log("drawDCTower", pillarData)

    //Pfeiler
    let m1t2a = []
    let m1t2mean = []

    pillarData.map((pillar) => {

      let avg_x = parseFloat(pillar?.chartStats.avg_x) - DCTOWER_OFFSET.x
      let avg_y = parseFloat(pillar?.chartStats.avg_y) - DCTOWER_OFFSET.y

        m1t2a.push({x: avg_x, y: avg_y})
        m1t2mean.push({x: avg_x, y: avg_y})
    })

    //m1t2a.push({ x: 8.46, y: 1.85 })
    //m1t2a.push({ x: 4.23, y: 5.55 })
    //m1t2a.push({ x: -4.23, y: 3.70 })

    //console.log("m1t2aus",m1t2a)
    setDCTowerM1T2Mean(m1t2mean)

    //DC Tower
    /*setDCTower([
      { x: -4.23, y: 3.70 },
      { x: 0, y: 0 },
      { x: 8.46, y: 7.40 },
      { x: 4.23, y: 11.10 },
      { x: -4.23, y: 3.70 },
    ])*/

    setDCTower([
      { x: -15, y: 10 },
      { x: 7, y: -10 },
      { x: 42, y: 20 },
      { x: 20, y: 40 },
      { x: -15, y: 10 },
    ])

    //DC Tower Animation
    let animationM1T2Datasets = [];

    pillarData.map((pillar, pindex) => {

      if(pillar?.chartData?.datasets[0].data){
        pillar?.chartData?.datasets[0].data.map((d, dindex) => {
          //console.log("Data: ",d)
          if(d){

              let m1t2 = animationM1T2Datasets[dindex]?.data
              if(!m1t2){
                m1t2 = JSON.parse(JSON.stringify(m1t2a));
              }
              m1t2[pindex].color = pillar.color
              m1t2[pindex].label = pillar.name
              m1t2[pindex].datalabels = { color: pillar.color}
              m1t2[pindex].x = m1t2[pindex].x + (d.x/10) //mm in cm => /10
              m1t2[pindex].y = m1t2[pindex].y + (d.y/10) //mm in cm => /10

              //Abweichung vom Mittelwert
              /*if(pindex == 0){
                m1t2[3].x = m1t2a[0].x
                m1t2[3].y = m1t2a[0].y
                //Letzter Punkt
                m1t2[4].x = m1t2[0].x
                m1t2[4].y = m1t2[0].y
              }

              if(pindex == 1){
                m1t2[2].x = m1t2a[1].x
                m1t2[2].y = m1t2a[1].y
              }*/

              animationM1T2Datasets[dindex] = JSON.parse(JSON.stringify({time: d.time, data: m1t2}))
          }
        })  
      }

    })

    //Animationsdaten setzen
    let data = animationM1T2Datasets[0].data
    let time = animationM1T2Datasets[0].time
    setAnimationM1T2Data({time: time, data:data})
    setDCTowerAnimationM1T2Datasets(animationM1T2Datasets);
  }

  /**
   * Chart Kölnbreinsperre
   * @param {*} pillarData 
   */
  const drawStaumauer = (pillarData) => {
    console.log("drawStaumauer", pillarData)

    let m1t5a = []
    let d3a = []

    let m1t5mean = []
    let d3mean = []

    pillarData.map((pillar) => {

      let avg_x = parseFloat(pillar?.chartStats.avg_x) - STAUMAUER_OFFSET.x
      let avg_y = parseFloat(pillar?.chartStats.avg_y) - STAUMAUER_OFFSET.y

      if(pillar.name == "D3"){
        d3a.push({x: avg_x, y: avg_y, label: pillar.name})
        d3mean.push({x: avg_x, y: avg_y, label: pillar.name})
      }else{
        m1t5a.push({x: avg_x, y: avg_y, label: pillar.name})
        m1t5mean.push({x: avg_x, y: avg_y, label: pillar.name})
      }
    })

    setStaumauerD3Mean(d3a)
    setStaumauerM1T5Mean(m1t5a)

    let animationD3Datasets = [];
    let animationM1T5Datasets = [];

    pillarData.map((pillar, pindex) => {

      if(pillar?.chartData?.datasets[0].data){
        pillar?.chartData?.datasets[0].data.map((d, dindex) => {
          //console.log("Data: ",d)
          if(d){
            if(pillar.name == "D3"){
              let d3 = animationD3Datasets[dindex]?.data
              if(!d3){
                d3 = JSON.parse(JSON.stringify(d3a));

              }
              d3.color = pillar.color
              //d3.label = pillar.name
              d3.datalabels = { color: pillar.color}
              d3.x = d3a.x + d.x * 2
              d3.y = d3a.y + d.y * 2
              animationD3Datasets[dindex] = {time: d.time, data: d3}
            }else{
              let m1t5 = animationM1T5Datasets[dindex]?.data
              if(!m1t5){
                m1t5 = JSON.parse(JSON.stringify(m1t5a));
              }
              m1t5[pindex].color = pillar.color
              //m1t5[pindex].label = pillar.name
              m1t5[pindex].datalabels = { color: pillar.color}
              m1t5[pindex].x = m1t5[pindex].x + d.x * 2
              m1t5[pindex].y = m1t5[pindex].y + d.y * 2

              console.log("Pillar dx dy", pillar.name, d.x, d.y)

              //m1t5[pindex].x = m1t5[pindex].x + (d.x/10) //mm in cm => /10
              //m1t5[pindex].y = m1t5[pindex].y + (d.y/10) //mm in cm => /10

              animationM1T5Datasets[dindex] = JSON.parse(JSON.stringify({time: d.time, data: m1t5}))
            }
          }
        
        })  
      }

    })

    //Animationsdaten setzen
    let data = animationM1T5Datasets[0].data
    let time = animationM1T5Datasets[0].time
    setAnimationM1T5Data({time: time, data:data})
    setStaumauerAnimationM1T5Datasets(animationM1T5Datasets);

    let dataD3 = animationD3Datasets[0].data
    let timeD3= animationD3Datasets[0].time
    setAnimationD3Data({time: timeD3, data:dataD3})
    setStaumauerAnimationD3Datasets(animationD3Datasets);

  }

  const changeBeginTime = (time) => {
    setBegin(time)

    filter.begin = time
    setFilter(filter)

    //Daten laden
    fetchMeasurements(testsite?.id, checkedPillarData, filter)

    /*checkedPillarData.map(pillar => {
      fetchMeasurements(pillar, filter)
    })*/

    fetchDisplacements(testsite?.id, checkedPillarData, filter, weatherOptionsState)

    fetchWeatherdata(testsite?.id, filter, campaign?.weathercolumns)

  }

  const changeEndTime = (time) => {
    setEnd(time)

    filter.end = time
    setFilter(filter)

    //Daten laden
    fetchMeasurements(testsite?.id, checkedPillarData, filter)

    /*checkedPillarData.map(pillar => {
      fetchMeasurements(pillar, filter)
    })*/

    fetchDisplacements(testsite?.id, checkedPillarData, filter, weatherOptionsState)

    fetchWeatherdata(testsite?.id, filter, campaign?.weathercolumns)

  }

  const exportPdf = async () => {
    console.log("exportPdf")
    /*const doc = new jsPDF()
    doc.text("InfraHealth Export",10,10)
    doc.save("../public/export/my.pdf")*/

    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await document.querySelector("#dataContainer");
    pdf.html(data).then(() => {
      pdf.save("../public/export/my.pdf");
    });

  }

  
  return (
  <div>
    <Menu/>
    <Header/>

    <Container sx={{mt: 6, mb: 24}}>

      <Grid container spacing={2} sx={{m: 2}}>
          <Box sx={{m:2}}>
            <Select
              options={selectTestsiteOptions} 
              value={testsiteOptionsState}
              onChange={(option) => {
                console.log("ChangeOptions:")
                setTestsiteOptionsState(option)

                setCheckedPillarData([])

                setCheckedWeatherData([])

                setMaxX(null)
                setMaxY(null)

                testsites?.map(ts =>{

                if(ts.id == option.value){
                  setTestsite(ts)
                  map.current.setCenter(ts.center)
                  map.current.setZoom(ts.zoom)

                  //Campaign
                  fetchCampaigns(ts.id)
                }
              })
            }} />
          </Box>
          <Box sx={{m:2}}>
            <Select 
              options={selectCampaignOptions} 
              value={campaignOptionsState}
              onChange={(option) => {
                setCampaignOptionsState(option)

                setMaxX(null)
                setMaxY(null)

                campaigns?.map(c => {
                  if(c.id == option.value){
                    setCampaign(c)
                    let begin = c.time_begin
                    let end = c.time_end
                    let momentBegin = moment(begin)
                    let momentEnd = moment(end)
                    setBegin(momentBegin)
                    setMinBegin(momentBegin)
                    setEnd(momentEnd)
                    setMaxEnd(momentEnd)
                    filter.begin = momentBegin
                    filter.end = momentEnd
                    setFilter(filter)

                    //Daten laden
                    fetchMeasurements(testsite?.id, c.pillars, filter)

                    /*c.pillars.map(pillar => {
                      fetchMeasurements(pillar, filter)
                    })*/
                    setCheckedPillarData(c.pillars)

                    fetchDisplacements(testsite?.id, c.pillars, filter, weatherOptionsState)

                    fetchWeatherdata(testsite?.id, filter, campaign?.weathercolumns)

                  }
                })
            }} />
          </Box>

          <MobileDateTimePicker
            sx={{m:1}}
            label="Beginn"
            value={begin}
            minDate={minBegin}
            onChange={changeBeginTime}
          />

          <MobileDateTimePicker
            sx={{m:1}}
            label="Ende"
            value={end}
            maxDate={maxEnd}
            onChange={changeEndTime}
          />

          <IconButton
            aria-label="pdf"
            id="pdf-button"
            onClick={exportPdf}
          >
            <PictureAsPdf />
          </IconButton>

        </Grid>

        {/** Grid total width = 12 xs = Integer zwischen 1 und 12 */}
       

        <div id="dataContainer">

        <Grid container spacing={2}>

            <Grid item xs={4}>
              <div ref={mapContainer} className="map-container"/>
            </Grid>

            <Grid item xs={8}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Messpunkte (Winkel)</FormLabel>              
                <FormGroup>
                {campaign?.pillars?.map(pillar =>
                  <PillarCheckBox pillar={pillar} onChangePillar={onChangePillar} showEditPillarDialog={showEditPillarDialog}/>
                )}
                </FormGroup>
              </FormControl>

              {/** Wetterdaten */}
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Wetterdaten (untere und obere Grenze)</FormLabel>              
                <FormGroup>
                {campaign?.weathercolumns?.map(column =>
                  <WeatherColumnCheckBox column={column} onChangeWeatherColumn={onChangeWeatherColumn} showEditWeathercolumnDialog={showEditWeathercolumnDialog}/>
                )}   
                </FormGroup>
              </FormControl>
            </Grid>

            {/** Staumauer */}
            {testsite?.id == Testsite.KOELNBREINSPERRE && staumauerAnimationM1T5Datasets ? 
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{m:1}}>
                  <Chart ref={chartStaumauerRef} type='scatter' data={{
                    //labels: labels,
                    datasets: [
                      {
                        label: "D3",
                        labels: ["D3"],
                        datalabels: {anchor: 'center', align: 'bottom'} ,
                        data: animationD3Data?.data,
                        backgroundColor: 'rgba(255, 0, 70, 1)',
                        showLine: true,
                        lineTension: 0.4,
                        borderColor: 'rgba(255, 0, 70, 1)',
                        pointRadius: 5,
                        pointColor: animationD3Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                        pointBackgroundColor: animationD3Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                      },
                      {
                        label: "M1 - M5",
                        labels: ["M1","M2","M3","M4","M5"],
                        datalabels: {anchor: 'center', align: 'bottom'} ,
                        data: animationM1T5Data?.data,
                        backgroundColor: 'rgba(255, 0, 70, 1)',
                        showLine: true,
                        lineTension: 0.4,
                        borderColor: 'rgba(255, 0, 70, 1)',
                        pointRadius: 5,
                        pointColor: animationM1T5Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                        pointBackgroundColor: animationM1T5Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                      },
                      {
                        label: "Verschiebung",
                        datalabels: {anchor: 'bottom', align: 'right'} ,
                        data: [{
                          x : 150,
                          y : 0,
                          label: ""
                        },{
                          x : 160,
                          y : 0,
                          label: "5mm"
                        }],
                        showLine: true,
                        pointRadius: 0,
                        borderColor: 'rgba(255, 0, 70, 1)',
                      },
                      /*{
                        label: "April D3",
                        data: [{x : 165, y : 208}],
                        backgroundColor: 'rgba(0, 255, 70, 1)',
                        borderColor: 'rgba(0, 255, 70, 1)',
                      },
                      {
                        label: "April M1 - M5",
                        labels: ["M1","M2","M3","M4","M5"],
                        data: [{x : 0, y : 0}, {x : 104, y : 89}, {x : 173, y : 206}, {x : 178, y : 347}, {x : 121, y : 496}],
                        backgroundColor: 'rgba(0, 255, 70, 1)',
                        showLine: true,
                        lineTension: 0.4,
                        borderColor: 'rgba(0, 255, 70, 1)',
                      },*/
                      {
                        label: "Mean D3",
                        datalabels: {labels: {title: null}} ,
                        data: staumauerD3Mean,
                        backgroundColor: 'rgba(211,211,211,1)',
                        borderColor: 'rgba(211,211,211,1)',
                      },
                      {
                        label: "Mean M1-M5",
                        labels: ["M1","M2","M3","M4","M5"],
                        datalabels: {labels: {title: null}} ,
                        data: staumauerM1T5Mean,
                        backgroundColor: 'rgba(211,211,211,1)',
                        showLine: true,
                        lineTension: 0.4,
                        borderColor: 'rgba(211,211,211,1)',
                      }
                      /*{
                        label: "October D3",
                        data: [{x : 125, y : 225}],
                        backgroundColor: 'rgba(70, 0, 255, 1)',
                        borderColor: 'rgba(70, 0, 255, 1)',
                      },
                      {
                        label: "October M1 - M5",
                        labels: ["M1","M2","M3","M4","M5"],
                        data: [{x : 0, y : 0}, {x : 69, y : 109}, {x : 133, y : 221}, {x : 148, y : 352}, {x : 120, y : 500}],
                        backgroundColor: 'rgba(70, 0, 255, 1)',
                        showLine: true,
                        lineTension: 0.4,
                        borderColor: 'rgba(70, 0, 255, 1)',
                      },*/
                      
                    ],
                  }} 
                  options={staumauerChartOptions} />             
                </Card>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                  <IconButton
                    aria-label="play"
                    id="replay10-button"
                    onClick={() => replayAnimation(10)}
                  >
                    <Replay10 />
                  </IconButton>
                  <IconButton
                    aria-label="play"
                    id="replay-button"
                    onClick={() => replayAnimation(1)}
                  >
                    <SkipPrevious />
                  </IconButton>
                  <IconButton
                    aria-label="play"
                    id="play-button"
                    onClick={animationStep == 0 || animationPaused ? playAnimation : pauseAnimation}
                  >
                    {animationStep == 0 || animationPaused ? <PlayIcon /> : <PauseIcon />} 
                  </IconButton>
                  <IconButton
                    aria-label="stop"
                    id="stop-button"
                    onClick={stopAnimation}
                  >
                    <StopIcon />
                  </IconButton>
                  <IconButton
                    aria-label="forward"
                    id="forward-button"
                    onClick={() => forwardAnimation(1)}
                  >
                    <SkipNext />
                  </IconButton>
                  <IconButton
                    aria-label="forward10"
                    id="forward10-button"
                    onClick={() => forwardAnimation(10)}
                  >
                    <Forward10 />
                  </IconButton>
                  <Grid item xs={6}>
                    {animationM1T5Data?.time}
                  </Grid>
                </div> 

              </Grid>
              <Grid item xs={6}>
                  <Card variant="outlined">
                    {checkedWeatherData[0]?.chartData ? 
                    <Chart 
                      ref={chartWetterRef} 
                      type='line' 
                      data={checkedWeatherData[0]?.chartData} 
                      options={weatherColumnChartOptions(checkedWeatherData[0])}
                      //options={{ maintainAspectRatio: false }}
                    />
                    : 
                    null
                    }
                  </Card>
                  <Card variant="outlined">
                    {checkedWeatherData[6]?.chartData ? 
                    <Chart 
                      ref={chartWetterRef} 
                      type='line' 
                      data={checkedWeatherData[6]?.chartData} 
                      options={weatherColumnChartOptions(checkedWeatherData[6])}
                      //options={{ maintainAspectRatio: false }}
                    />
                    : 
                    null
                    }
                  </Card>
                </Grid>
              </Grid>
            : null}

            {/** DC Tower */}
            {testsite?.id == Testsite.DCTOWER && dcTowerAnimationM1T2Datasets ? 
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{m:1}}>
                  <Chart ref={chartStaumauerRef} type='scatter' data={{
                    //labels: labels,
                    datasets: [
                      {
                        label: "M1 - M2",
                        labels: ["M1","M2"], 
                        datalabels: {anchor: 'center', align: 'bottom'} ,
                        data: animationM1T2Data?.data,
                        backgroundColor: 'rgba(255, 0, 70, 1)',
                        showLine: true,
                        //lineTension: 0.4,
                        borderColor: 'rgba(255, 0, 70, 1)',
                        pointRadius: 5,
                        pointColor: animationM1T2Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                        pointBackgroundColor: animationM1T2Data?.data?.map(datapoint => {
                          return datapoint.color
                        }),
                      },
                      {
                        label: "Verschiebung",
                        datalabels: {anchor: 'bottom', align: 'right'} ,
                        data: [{
                          x : 0,
                          y : 5,
                          label: ""
                        },{
                          x : 1,
                          y : 5,
                          label: "1cm"
                        }],
                        showLine: true,
                        pointRadius: 0,
                        borderColor: 'rgba(255, 0, 70, 1)',
                      },
                      {
                        label: "Mean M1-M2",
                        labels: ["M1","M2"],
                        data: dcTowerM1T2Mean,
                        datalabels: {labels: {title: null}} ,
                        backgroundColor: 'rgba(211,211,211,1)',
                        showLine: true,
                        //lineTension: 0.4,
                        borderColor: 'rgba(211,211,211,1)',
                      },
                      {
                        label: "DC Tower",
                        data: dcTower,
                        datalabels: {labels: {title: null}} ,
                        backgroundColor: 'rgba(66,66,66,1)',
                        showLine: true,
                        //lineTension: 0.4,
                        borderColor: 'rgba(66,66,66,1)',
                        borderDash: [5, 5],
                      },
                      {
                        label: "Wind",
                        data: windAnimationData,
                        datalabels: {anchor: 'center', align: 'bottom'} ,
                        //datalabels: {labels: {title: null}} ,
                        backgroundColor: 'rgba(40,67,135,1)',
                        borderColor: 'rgba(40,67,135,1)',
                        showLine: true,
                        pointStyle:'triangle',
                        pointRadius: 5,
                        rotation: windAnimationData?.map(datapoint => {
                          return datapoint.rotation
                        }),
                        pointColor: 'rgba(40,67,135,1)',
                        pointBackgroundColor: 'rgba(40,67,135,1)'
                      }
                    ],
                  }} 
                  options={dcTowerChartOptions} />             
                </Card>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                  <IconButton
                    aria-label="play"
                    id="replay10-button"
                    onClick={() => replayAnimation(10)}
                  >
                    <Replay10 />
                  </IconButton>
                  <IconButton
                    aria-label="play"
                    id="replay-button"
                    onClick={() => replayAnimation(1)}
                  >
                    <SkipPrevious />
                  </IconButton>
                  <IconButton
                    aria-label="play"
                    id="play-button"
                    onClick={animationStep == 0 || animationPaused ? playAnimation : pauseAnimation}
                  >
                    {animationStep == 0 || animationPaused ? <PlayIcon /> : <PauseIcon />} 
                  </IconButton>
                  <IconButton
                    aria-label="stop"
                    id="stop-button"
                    onClick={stopAnimation}
                  >
                    <StopIcon />
                  </IconButton>
                  <IconButton
                    aria-label="forward"
                    id="forward-button"
                    onClick={() => forwardAnimation(1)}
                  >
                    <SkipNext />
                  </IconButton>
                  <IconButton
                    aria-label="forward10"
                    id="forward10-button"
                    onClick={() => forwardAnimation(10)}
                  >
                    <Forward10 />
                  </IconButton>
                  <Grid item xs={6}>
                    {animationM1T2Data?.time}
                  </Grid>
                </div> 

              </Grid>

              <Grid item xs={6}>
                <Card variant="outlined">
                  {checkedWeatherData[2]?.chartData ? 
                  <Chart 
                    ref={chartWetterRef} 
                    type='line' 
                    data={checkedWeatherData[2]?.chartData}
                    /*data={{
                      labels:checkedWeatherData[2]?.chartData.labels,
                      datasets:[
                      checkedWeatherData[2]?.chartData.datasets[0],
                      {
                        label: 'Aktuell',
                        datalabels: {labels: {title: null}} ,
                        data: [{datum: "4.11. 10:00", value: weatherWindAnimationData}],
                        pointRadius: 5,
                        borderColor: 'rgba(155, 199, 132)',
                        backgroundColor: 'rgba(155, 199, 132, 0.5)',
                      }
                    ]}} */
                    options={weatherColumnChartOptions(checkedWeatherData[2])}
                    //options={{ maintainAspectRatio: false }}
                  />
                  : 
                  null
                  }
                </Card>
                <Card variant="outlined">
                  {checkedWeatherData[3]?.chartData ? 
                  <Chart 
                    ref={chartWetterRef} 
                    type='line' 
                    data={checkedWeatherData[3]?.chartData} 
                    options={weatherColumnChartOptions(checkedWeatherData[3])}
                    //options={{ maintainAspectRatio: false }}
                  />
                  : 
                  null
                  }
                </Card>
              </Grid>
            </Grid>

            : null}

            {radialDisplacementChart?.data ? 
             <Grid item xs={6}>
              <Card variant="outlined">
                <Chart ref={chartPfeilerDispRadRef} type='scatter' data={radialDisplacementChart?.data} options={radialDisplacementChart?.options}/>
              </Card>
              <Select
                  options={selectWeatherOptions} 
                  value={weatherOptionsState}
                  onChange={(option) => {
                    console.log("ChangeWeatherColumn")
                    setWeatherOptionsState(option)

                    fetchDisplacements(testsite?.id, checkedPillarData, filter, option)
                  }}
                />
            </Grid>
            : null}

            {tangentialDisplacementChart?.data ? 
            <Grid item xs={6}>
              <Card variant="outlined">
                <Chart ref={chartPfeilerDispTanRef} type='scatter' data={tangentialDisplacementChart?.data} options={tangentialDisplacementChart?.options}/>
              </Card>
            </Grid>
            : null}

            <Grid item xs={12}>
              <Card variant="outlined">
                {totalDisplacementChart?.data ? 
                <Chart ref={chartPfeilerDispTotRef} type='scatter' data={totalDisplacementChart?.data} options={totalDisplacementChart?.options}/>
                : <p>Keine Daten verfügbar.</p>}
              </Card>
            </Grid>

            {checkedPillarData.map(pillar => 
            <Grid item xs={6}>
              <Card variant="outlined">
                {pillar?.chartData ? 
                <Chart ref={chartPfeilerRef} type='bubble' data={pillar?.chartData} options={pillarChartOptions(pillar)} />
                : 
                <p>Keine Daten verfügbar.</p>
                }
              </Card>
            </Grid>
            )}         

            {checkedWeatherData.map(column => 
              <Grid item xs={12}>
                <Card variant="outlined">
                  {column?.chartData ? 
                  <Chart 
                    ref={chartWetterRef} 
                    type='line' 
                    data={column.chartData} 
                    options={weatherColumnChartOptions(column)}
                    //options={{ maintainAspectRatio: false }}
                  />
                  : 
                  <p>Keine Daten verfügbar.</p>
                  }
                </Card>
              </Grid>
            )}
          </Grid>
          </div>

        </Container>

        {editPillar ?
        <EditPillarDialog
            open={editPillarDialogVisible}
            pillar={editPillar}   
            savePillar={savePillar}
            setEditPillarDialogVisible={setEditPillarDialogVisible}  
        />
        :null}

        {editWeathercolumn ?
          <EditWeathercolumnDialog
            open={editWeathercolumnDialogVisible}
            column={editWeathercolumn}   
            saveWeathercolumn={saveWeathercolumn}
            setEditWeathercolumnDialogVisible={setEditWeathercolumnDialogVisible}  
        />
        :null}

    <Footer/>

  </div>
  );
  
}