import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function PillarCheckBox({pillar, onChangePillar, showEditPillarDialog}) {

  const MIN_VALUE = 0
  const MAX_VALUE = 360

  const ITEM_HEIGHT = 48;

  const [checked, setChecked] = useState(pillar.checked);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    console.log("handleClick: ", e)
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    console.log("handleClose: ")
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e) => {

    let option = e.target.value
    
    switch(option){
      //Editieren
      case 0:
        showEditPillarDialog(pillar)
        break;
    }

    setAnchorEl(null);
  };

  const options = [
    {id: 1, name: 'Editieren'}
  ];

  /**
   * Change main movement direction of pillar
   * @param {*} checked 
   */
  const changeChecked = (e) => {
    let checked = e.target.checked
    console.log("changeChecked", checked)
    setChecked(checked)
    pillar.checked = checked

    onChangePillar(pillar)
  }

  return (
    <Box key={pillar.id} sx={{ display: 'flex', flexDirection: 'row'}}>
      <FormControlLabel 
        control={<Checkbox 
          value={pillar.id} 
          checked={checked}
          onChange={changeChecked}
          style ={{
            color: pillar?.color ?? '#FF0000',
          }}
          //color={pillar?.color ?? '#FF0000'}
          />} 
        label={pillar.name+" ("+(pillar.angle_rad_displacement ?? 0)+"°)"} 
      />
      <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} selected={option.name === 'Editieren'} onClick={(option) => handleMenuItemClick(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
    </Box>
  );
}

