import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "../styles/Login.css";
import Helper from "../classes/Helper";

function Login() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [user, loading, authError] = useAuthState(auth);

  const navigate = useNavigate();
  
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (user) navigate("/dashboard");
  }, [user, loading]);

  const onSubmit = async (event) => {
    event.preventDefault();

    // validate the inputs
    if (!email || !password) {
      setErrorMessage("Bitte gültige Email-Adresse und gültiges Passwort eingeben.");
      return;
    }

    // clear the errors
    setErrorMessage("");

    // TODO: send the login request
    if (Helper.isEmail(email) && password) {
      //Firebase-Anmeldung durchführen
      signInWithEmailAndPassword(auth, email, password)
        .catch((err) => {
            console.log("Fehler Anmeldung: err = ", err)
            if (err.code) {
                setErrorMessage(Helper.getFirebaseErrorMsg(err.code) )
            } else {
                setErrorMessage("Fehler bei der Anmeldung.")
            }
        });
    } else if (Helper.isEmail(email) && !password) {
      setErrorMessage("Sie müssen ein Passwort eingeben.")
    } else if (!Helper.isEmail(email) && password) {
      setErrorMessage("Sie müssen eine gültige Email-Adresse eingeben.")
    } else {
      //setErrorMessage("Sie müssen eine Email-Adresse und ein Passwort eingeben.")
    }

  }

  return (
    <div className="login">
      <div className="login__container">
        <h1>InfraHealth</h1>
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email-Adresse"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
        />
        
        {errorMessage 
        ?   <span className="error">{errorMessage}</span>
        :   null
        }

        <button
          className="login__btn"
          //onClick={() => signInWithEmailAndPassword(email, password)}
          onClick={onSubmit}
        >
          Anmelden
        </button>
        {/*
            <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button>
        */
        }
        

        <div>
          <Link to="/reset">Passwort vergessen?</Link>
        </div>
        {/*
        <div>
          Keinen Account? Jetzt <Link to="/register">registrieren</Link>.
        </div>*/}
      </div>
    </div>
  );
}
export default Login;