
import { useNavigate } from "react-router-dom";


import React, { useState, useEffect } from 'react';
import Colors from '../styles/Colors';

import { logout } from "../firebase";
import { Link } from 'react-router-dom';

export default function Menu() {

    const navigate = useNavigate();

    const MENU_HEIGHT = 50;

    const [isSticky, setSticky] = useState(false);
    const handleScroll = () => {
        const windowScrollTop = window.scrollY;
        if (windowScrollTop > 10) {
            setSticky(true); 
        } else {
            setSticky(false);
        }
    }; 

    useEffect(() => {
 	    window.addEventListener('scroll', handleScroll); 
 	    return () => {
 		    window.removeEventListener('scroll', handleScroll);
 	    };
 	 }, []);

    function logOut() {
        console.log("logOut")
        try {
            logout();
            navigate("/login");

        } catch (err) {
            console.error(err);
        }
    }

    return (
        <header 
            style={{ 
                background: isSticky ? '#fff' : '', 
                width: '100%', 
                zIndex: '999',
                position:isSticky ? 'fixed' : 'absolute' 
            }}
		 > 
            <div className="menu" style={{backgroundColor: Colors.MAIN_COLOR_LIGHT, height: MENU_HEIGHT, }}>
                <div style={{display: 'flex'}}>
                    <Link className='home' title="Home" to="/"><img src={require("../images/icons8-damm-96.png")} alt="InfraHealth"/></Link>
                    <Link className='item' title="Import" to="/import">Import</Link>
                    <Link className='item' title="Visualisierung" to="/karte">Visualisierung</Link>
                    <Link className='item' title="Dynamic" to="/dynamic">Dynamisch</Link>
                    <Link className='item' title="Export" to="/export">Export</Link>
                    <Link className='item' title="Über InfraHealth" to="/about">Facts</Link>
                </div>

                <div>
                    <span className='username'>
                        {/*AuthUser.email*/} 
                    </span>
                    <a className='logout' title="Abmelden" href={logOut} onClick={() => logOut()}> Abmelden</a>
                </div>
            
                <style jsx>{`
                    .menu{
                        position: 'fixed';
                        top: 0;
                        width: 100%; 
                        z-index: 999;
                        display:flex; 
                        //background-color: green;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .home {
                        height: 50px;
                        display: flex;
                        padding: 8px;
                    }

                    .item, .username, .logout {
                        padding: 16px 8px;
                    }

                    .item, .logout {
                        cursor: pointer;
                    }

                    .item:hover, .logout:hover, .home:hover{
                        background-color: #0070f3;
                    }

                    .right {
                        float: right;
                    }

                    
                `}
            
                </style>
            </div>
        </header>
  )}