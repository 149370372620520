//import { Title } from "@mui/icons-material";

import React, {  }  from 'react';

export default function Header() {
  return (
    <div>
      InfraHealth
    </div>
  )}
  
  /*return (
    <Title>
        <title>InfraHealth</title>
        <link rel="icon" href="/images/icons8-damm-96.png" />
    </Title>
  )}
  */