import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/Menu";

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

//export default function Import() {
const Visualisierung = () => {  

  const styles = {
    imageBig: {
      width: '600px',
      margin: 8
    },
    imageSmall: {
      width: '300px',
      margin: 8
    },
  }

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Visualisierung. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);

  return (
  <div>

    <Menu/>
    <Header/>

    <div className="container">
      <main style={{ margin: '0 4rem 10rem', }}>
        <h1>Visualisierung</h1>

        <div className="content">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={require("../images/Diagrammbeispiel.png")} alt="Diagrammbeispiel" title="InfraHealth Diagrammbeispiel" style={styles.imageBig} />
          </div>
        </div>
      </main>  
    </div>

    <Footer/>
  </div>
  )
}

export default Visualisierung;