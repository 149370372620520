import Footer from '../components/Footer'
import Menu from '../components/Menu'

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import background from "../images/reservoir_1920.jpg"

//export default function Dashboard() {
const Dashboard = () => {

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Dashboard. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);

  return (
    <div>

        <Menu/>

        <div className="container" 
          style={{
            backgroundImage: `url(${background})`,
          }}
        >

        <main style={{marginTop: '40px', marginBottom: '80px'}}>
          
          <h1 className="title" style={{color: 'white', textAlign: 'center'}}>
            Willkommen bei InfraHealth! 
          </h1>

          <h2 style={{color: 'white', textAlign: 'center'}}>Satellitengestützte Überwachung Kritischer Infrastruktur</h2>

          <div className="grid"> 
            <a href="import" className="card">
              <h3>Import</h3>
              <p>Importieren von Messdaten.</p>
            </a>

            <a href="karte" className="card">
              <h3>Visualisierung</h3>
              <p>Grafische Darstellung.</p>
            </a>

            <a href="export" className="card">
              <h3>Export</h3>
              <p>Exportieren der Daten.</p>
            </a>

            <a href="about" className="card">
              <h3>Über InfraHealth</h3>
              <p>Projektinformationen.</p>
            </a>
          </div>
        </main>
      </div>

      <Footer/>

    </div>
  )
}
export default Dashboard

/*export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  authPageURL: '/',
})(Dashboard)*/


