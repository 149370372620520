import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

//export default function Export() {
const Export = () => {  

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Export. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);


  return (
  <div>
    
    <Menu/>
    <Header/>
    <div className="container">
      <main>
        <h1>Export</h1>

        <div className="content">
          Hier wird bald exportiert werden...
        </div>
      </main>
    </div>
    <Footer/>
  </div>
  )
}
export default Export

/*export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  authPageURL: '/',
})(Export)*/