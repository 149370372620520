export default class Helper {
    static isEmail(value: string){
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }

    //Hinweis auf fehlendes Recht
    static getFirebaseErrorMsg (errorCode: string) {

        let errorMsg = "Ups, etwas ist schiefgelaufen"

        switch (errorCode) {
            case "auth/email-already-in-use":
                errorMsg = "Email-Adresse ist bereits registriert."
            break

            case "auth/network-request-failed":
                errorMsg = "Keine Internetverbindung vorhanden."
            break

            case "auth/wrong-password":
                errorMsg = "Falsches Passwort eingegeben."
            break

            case "auth/user-not-found":
             case "auth/user-disabled":
                errorMsg = "Die eingegebene Email-Adresse ist nicht registriert."
            break

            case "auth/invalid-email":
                errorMsg = "Die eingegebene Email-Adresse ist ungültig."
            break

            case "auth/weak-password":
                errorMsg = "Das eingegebene Passwort ist ungültig."
            break

            case "auth/too-many-requests":
                errorMsg = "Zu viele Anmeldeversuche wurden durchgeführt."
            break

        }

        return errorMsg
    }

}