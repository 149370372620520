import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "../firebase";
import Helper from "../classes/Helper";

import "../styles/Reset.css";

function Reset() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  const onSubmit = async (event) => {
    event.preventDefault();

     if (!email) {
      setErrorMessage("Bitte gültige Email-Adresse eingeben.");
      return;
    }

     if ( Helper.isEmail(email) ) {
        sendPasswordResetEmail(auth, email)
        .then((res) => {
            navigate("/login");
        })
        .catch((err) => {
            console.log("Fehler Passwort zurücksetzen: err = ", err)
            if (err.code) {
                setErrorMessage(Helper.getFirebaseErrorMsg(err.code) )
            } else {
                setErrorMessage("Fehler bei der Anmeldung.")
            }
        });
     } else {
        setErrorMessage("Bitte gültige Email-Adresse eingeben."); 
     }
  
  
  }

  

  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email-Adresse"
        />

        {errorMessage 
        ?   <span className="error">{errorMessage}</span>
        :   null
        }

        <button
          className="reset__btn"
          onClick={onSubmit}
        >
          Passwort zurücksetzen
        </button>
        <div>
          <Link to="/login">Zur Anmeldung</Link>
        </div>
      </div>
    </div>
  );
}
export default Reset;