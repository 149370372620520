export default class Weathercolumn{
    
    static TEMPERATURE = 1;
    static NIEDERSCHLAG = 2;
    static LUFTFEUCHTIGKEIT = 3;
    static WINDSTAERKE = 4;
    static WINDRICHTUNG = 5;
    static STRAHLUNG = 6;
    static STAUHOEHE = 7;
    static LUFTDRUCK = 8;
    static TAUPUNKT = 9;

    constructor(
        protected _id: number, 
        protected _name: string, 
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    get value(){
        return this._id;
    }

    set name(value){
        this._name = value;
    }

    get name(){
        return this._name;
    }
}