import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import Papa from 'papaparse';

import { Button, Select, MenuItem, InputLabel } from '@mui/material';

//export default function Import() {
const Import = () => {  

  //let measurements = null

  const [user, loading, authError] = useAuthState(auth);
  const [errorMsg, setErrorMsg] = useState();
  const [measurements, setMeasurements] = useState();
  const [pillar, setPillar] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Import. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);

  const changeHandler = (event) => {
    console.log(event.target.files[0])
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        //console.log(results.data)
        setErrorMsg()
        if (results.data) setMeasurements(results.data);
       //measurements = results.data
      },
    });
  };

  const handlePillarChange = (value) => {
    console.log("handlePillarChange")
    setPillar(value)
  }

  const save = () => {
    if (measurements) {
      setErrorMsg();

      measurements.map((d, index) => {
        console.log("d: ", d)
        let zeit = d.Zeit;
        let rechtswert = d["Rechtswert [m]"] ?? d["Rechtswert[m]"];
        let hochwert = d["Hochwert [m]"] ?? d["Hochwert[m]"];
        let hoehe = d["Hoehe [m]"] ?? d["Hoehe[m]"];
        //console.log("Werte: ", zeit, rechtswert, hochwert, hoehe);
        //Hier Daten speichern

      })
    } else {
      console.log("Keine Daten importiert.")
      setErrorMsg("Keine Daten importiert.")
    }
  }

  return (
  <div>

    <Menu/>
    <Header/>
    <div className="container">
      <main>
        <h1>Import einer Messkampagne</h1>

        <div className="content" style={{textAlign: 'center'}}>
          <div style={{textAlign: 'center'}}>
            <h2>CSV im Format</h2> 
            <br></br>
            <span style={{fontFamily: 'Courier'}}>Zeit, Rechtswert[m], Hochwert[m], Hoehe[m]</span>
            <br></br>
            <span style={{fontFamily: 'Courier'}}>27.04.2022 13:00,261.228,215381.331,1904.253</span>
            <br></br>
            <span style={{fontFamily: 'Courier'}}>27.04.2022 14:00,261.234,215381.324,1904.277</span>
            <br></br>
            <span style={{fontFamily: 'Courier'}}>...</span>
            <br></br><br></br>
          </div>
         
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={changeHandler}
            style={{ display: "block", margin: "10px auto" }}
          />

          {errorMsg
          ? <span style={{backgroundColor: 'red', color: "white", margin: 36, padding: 4}}>{errorMsg}</span>
          : null
          }



          {measurements
          ? <div>
              <InputLabel id="demo-simple-select-label">Messpfeiler</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pillar}
                label="Messpfeiler"
                onChange={handlePillarChange}
              >
                <MenuItem value={1}>M1</MenuItem>
                <MenuItem value={2}>M2</MenuItem>
                <MenuItem value={3}>M3</MenuItem>
                <MenuItem value={4}>M4</MenuItem>
                <MenuItem value={5}>M5</MenuItem>
                <MenuItem value={6}>D3</MenuItem>
              </Select>
              <br></br>
              <Button variant="contained" style={{marginTop: 24}} onClick={save}>
                Import starten
              </Button>
            </div>
          : null}
          
          
        </div>
      </main>
    </div>
    
    <Footer/>
  </div>
  )
}
export default Import

/*export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  authPageURL: '/',
})(Import)*/