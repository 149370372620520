import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

 

const About = () => {

  const styles = {
    imageBig: {
      width: '600px',
      margin: 8
    },
    imageSmall: {
      width: '300px',
      margin: 8
    },
  }

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("About. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");
  }, [user, loading]);

  return (
  <div>

      <Menu/>
      <Header/>

      <div className="container">
        <main style={{ margin: '0 4rem 10rem', }}>
          <h1>Über Infrahealth</h1> 
        
          <div >
            <p>
              Bauwerke kritischer Infrastruktur müssen kontinuierlich überwacht werden, um bspw. den allgemeinen Zustand zu erfassen oder den Reparaturbedarf aufzuzeigen. Durch diese Maßnahmen wird eine Schadensprävention erreicht, wodurch die Lebensdauer erhöht und die Kosten für Sanierungsarbeiten gesenkt werden.
            </p>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src={require("../images/Monitoring.png")} alt="Monitoring" title="Automatisierte Kollisionskontrolle im Rahmen der VR-gestützten Messplanung" style={styles.imageSmall} />
              <img src={require("../images/Messplanung.png")} alt="Messplanung" title="Statisches und dynamisches Monitoring einer Talsperrez" style={styles.imageSmall} />
            </div>

            <p>
              Die Überwachung von Bauwerken wird traditionell durch ingenieur-geodätische Verfahren durchgeführt. Die steigenden Anforderungen an die Tragfähigkeit und Dauerhaftigkeit von Infrastruktur stellen eine immer größer werdende logistische und wirtschaftliche Herausforderung dar. Im Rahmen des Projektes InfraHealth sollen GNSS-basierte Verfahren untersucht und entwickelt werden, um die Überwachung kritischer Gebäudeinfrastrukturen zu vereinfachen und kostengünstiger anbieten zu können. 
            </p>

            <p>
              Als Kerntechnologien werden GNSS-basierte Empfänger untersucht, die statische und hochfrequente Bewegungen von Infrastrukturbauten erfassen. Anhand der statischen und spektralen Reaktion der Objekte auf dynamische Einflussgrößen wie Erdbeben, Lastwechsel (z.B. Umschaltvorgang bei Pump-Speicheranlagen), Verkehrs- oder Windeinfluss wird ein Fingerprint des Objektes generiert. Änderungen des Fingerprints ermöglichen Rückschlüsse auf den Zustand des Objektes. Im Rahmen des Projektes werden dezidierte Laboruntersuchungen unter kontrollieren Anregungen und in-situ Versuche auf realen Objekten durchgeführt. Basierend auf den erfassten Daten werden automatische Auswertealgorithmen entwickelt.
            </p>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src={require("../images/Systemarchitektur.png")} alt="Systemarchitektur" title="InfraHealth Systemarchitektur" style={styles.imageBig} />
            </div>

            <p>
              Die Projektergebnisse sollen als webbasiertes Service zur Verfügung gestellt werden. Darüber hinaus tragen die Erkenntnisse aus dem Projekt auch zu den im „Sendai-Framework for Disaster Risk Reduction 2015 - 2030“ der Vereinten Nationen definierten Zielen bei, in dem u.a. die Resilienz kritischer Infrastruktur gegenüber Naturgefahren erhöht wird.
            </p>

            <a href='../images/InfraHealth_Factsheet.pdf' target="_blank" title="FactSheet herunterladen">FactSheet herunterladen</a>
        </div>
       
        </main>
      
      <Footer/>
    </div>
  </div>
  )
}
export default About

/*export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  authPageURL: '/',
})(About)*/