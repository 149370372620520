import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, Slider, TextField } from '@mui/material';
import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function WeatherColumnCheckBox({column, onChangeWeatherColumn, showEditWeathercolumnDialog}) {

  const ITEM_HEIGHT = 48;

  const [checked, setChecked] = useState(column.checked);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    console.log("handleClick: ", e)
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    console.log("handleClose: ")
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e) => {

    let option = e.target.value
    
    switch(option){
      //Editieren
      case 0:
        showEditWeathercolumnDialog(column)
        break;
    }

    setAnchorEl(null);
  };

  const options = [
    {id: 1, name: 'Editieren'}
  ];

  /**
   * Change main movement direction of pillar
   * @param {*} checked 
   */
  const changeChecked = (e) => {
    let checked = e.target.checked
    console.log("changeChecked", checked)
    setChecked(checked)
    column.checked = checked

    onChangeWeatherColumn(column)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
      <FormControlLabel 
        control={<Checkbox 
          value={column.id} 
          checked={checked}
          onChange={changeChecked}
          //color={pillar?.color ?? '#FF0000'}
          />} 
        label={column.name+" ("+(column.lower_limit ?? 0)+column.unit+" bis "+(column.upper_limit ?? 0)+column.unit+")"} 

      />
      {/*<TextField 
        variant='standard'
        type="number" value={lower_limit}
        sx={{ m: 1, width: '10ch' }}
        onChange={event => changelower_limit(parseInt(event.target.value))}
        placeholder='Low lim.'
        InputProps={{
          endAdornment: <InputAdornment position="start">{column.unit}</InputAdornment>,
          label: "Low lim."
        }}
      />
      <TextField 
        variant='standard'
        type="number" value={upper_limit}
        sx={{ m: 1, width: '10ch' }}
        onChange={event => changeupper_limit(parseInt(event.target.value))}
        placeholder='High lim.'
        InputProps={{
          endAdornment: <InputAdornment position="start">{column.unit}</InputAdornment>,
          label: "High lim."
        }}
      />*/}
      <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} selected={option.name === 'Editieren'} onClick={(option) => handleMenuItemClick(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
    </Box>
  );
}

