import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { Card, Grid } from "@mui/material";
import { Chart } from "react-chartjs-2";


//import kinematicDataFile from '../data/dc/M1q_1hP_Mat.csv';
  //const DIMY = 36001

import kinematicDataFile from '../data/dc/M1qP_PM_2_P_Mat.csv';


//export default function Export() {
const Dynamic = () => {  

  const chartDynamicRef = useRef("chartDynamicRef");

  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  const [kinematicData, setKinematicData] = useState();
  const [chartData, setChartData] = useState();

  //File ALT
  /*const DIMX = 308
  const DIMY = 3601 //36001 = 10Hz
  const STEPX = 1
  const STEPY = 10*/

  //File NEU
  const DIMX = 308
  const DIMY = 201 //2001 = 10Hz
  const STEPX = 1
  const STEPY = 1

  //X = Tage


  useEffect(() => {
    //console.log("Export. useEffect(). user = ", user)

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authError) console.log("authError: ", authError)
    if (!user) navigate("/login");

  }, [user, loading]);

  useEffect(() => {

    let data = [];
    let values = []

    //Kinematic Data
    fetch(kinematicDataFile)
    .then(r => r.text())
    .then(text => {
      console.log('KinematicData text decoded:', text);
      text = text.replace(/(\r\n|\n|\r)/gm, ",");
      //Replace Tabstops
      text = text.replace(/\t/g, ',');
      let kinematicArray = text.split(",")
      console.log('KinematicArray:', kinematicArray);
      //let newArray = []
      //Create 2-dimensional Array 308 x 2001
      for (let i=0;i<DIMX;i+=STEPX){ //308
      //for (let i=0;i<308;i++){
          //newArray.push(kinematicArray.splice(0, DIMY))
        let spliced = kinematicArray.splice(0, DIMY-1)
        console.log("Spliced ",i,": ", spliced)
        for (let j=0;j<DIMY-1;j+=STEPY){
          let value = parseFloat(spliced[j])
          if(isNaN(value)) value = 0
          //x Frequenz, y Days
          data.push({
            x: i/STEPX, //Stunden in Tage
            y: j/STEPY, //Frequenz (Hz)
            value: value
          })
          values.push(value)
        }
      }

      let maxValue = Math.max(...values)

      setChartData({
        labels: values,
        datasets: [
          {
            label: "Amplitude [mm]",
            data: data,
            datalabels: {labels: {title: null}} ,
            //borderColor: "#FF0000",
            //backgroundColor: "#FF0000",
            showLine: false,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointStyle:'rect',
            pointColor: data.map(datapoint => {
              let color = 'rgb(211, 211, 211)'
              //NaN Values bleiben grau
              if(datapoint.value > 0){
                //#00008B Blau #ffff00 Gelb => Blau niedrige Frequenz
                color = pickHex(hexToRgb("#ffff00"),hexToRgb("#00008B"),datapoint.value/maxValue) ///Werte für Farbe auf max-Wert spreizen
              }
              console.log("Color: ", color, datapoint.value/maxValue, datapoint.value, maxValue, )
              return color
            }),
            pointBackgroundColor: data.map(datapoint => {
              let color = 'rgb(211, 211, 211)'
              //NaN Values bleiben grau
              if(datapoint.value > 0){
                //#00008B Blau #ffff00 Gelb => Blau niedrige Frequenz
                color = pickHex(hexToRgb("#ffff00"),hexToRgb("#00008B"),datapoint.value/maxValue) //Werte für Farbe auf max-Wert spreizen
              }
              return color
            }),
          }
        ],
      })

    });


  },[])

  const pickHex = (color1, color2, weight) => {
    //console.log("pickHex: ", color1, color2, weight)
      var w1 = weight;
      var w2 = 1 - w1;
      var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
          Math.round(color1[1] * w1 + color2[1] * w2),
          Math.round(color1[2] * w1 + color2[2] * w2)];
      return rgbToHex(rgb);
  }

  //#00008B,#ffff00

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16),parseInt(result[3], 16)] : null;
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex([r, g, b]) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }


  return (
  <div>
    
    <Menu/>
    <Header/>
    {chartData ? 
    <Grid item xs={6}>
      <Card variant="outlined">    
        <Chart ref={chartDynamicRef} type='scatter' data={chartData} 
          options={{
            //responsive: false,
            //aspectRatio: 1,
            plugins: {
              title: {
                display: true,
                text: 'Dynamic'
              },
              /*zoom: {
                pan: {
                  enabled: true,
                  mode: 'xy',
                },
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'xy'
                }
              },*/
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Days'
                },
                min: -5,
                max: Math.round((DIMX / STEPX) + 5),
                ticks: {
                  callback: function(value, index, ticks) {
                    return Math.floor(value * STEPX / 24)
                  }
                },
              },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: 'Frequenz [Hz]'
                  },
                  min: -5,
                  max: Math.round((DIMY / STEPY) + 5),
                  //min: 0,
                  //max: 1,
                  ticks: {
                    callback: function(value, index, ticks) {
                      return (value * STEPY / DIMY).toFixed(2)
                    }
                }
              },
            },
            parsing: {
              xAxisKey: 'x',
              yAxisKey: 'y'
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.labels[tooltipItem];
                    return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
                }
              }
          }
        }}/>        
      </Card>
    </Grid>
    : null}
  </div>
  )
}
export default Dynamic