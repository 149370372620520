import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, InputAdornment, Portal, TextField } from '@mui/material';
import { ReactAnglePicker } from 'react-angle-picker';

//import ColorPicker from 'react-native-wheel-color-picker'
//import NumericInput from 'react-native-numeric-input'

const EditPillarDialog = ({open, pillar, savePillar, setEditPillarDialogVisible}) => {  
    console.log("EditPillarDialog: ", open, pillar)
    
    const colorPickerRef = React.useRef("colorPicker");

    const MIN_VALUE = 0
    const MAX_VALUE = 360

    const [hexColor, setHexColor] = useState(pillar?.color ?? "#FFFFFF")
    const [angle, setAngle] = useState(pillar?.angle_rad_displacement ?? 0)

    const onChangeColor = (text) => {
        console.log("onChangeColor: ", text)

        //If Text is HexColor
        if(/^#[0-9A-F]{6}$/i.test(text)){
            setHexColor(text)
            pillar.color = text
        }
    }

      /**
   * Change main movement direction of pillar
   * @param {*} angle 
   */
  const changeAngle = (angle) => {

    if (angle > MAX_VALUE) angle = MAX_VALUE;
    if (angle < MIN_VALUE) angle = MIN_VALUE;

    setAngle(angle)
  }

  /**
   * Change main movement direction of pillar
   * @param {*} angle 
   */
  const afterChangeAngle = (angle) => {

    if (angle > MAX_VALUE) angle = MAX_VALUE;
    if (angle < MIN_VALUE) angle = MIN_VALUE;

    setAngle(angle)
    pillar.angle_rad_displacement = angle

  }

    return <div>
    <Dialog
      open={open}
      onClose={() => setEditPillarDialogVisible(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {pillar?.name} editieren
      </DialogTitle>
      <DialogContent>
        <Box sx={{flexDirection: 'column', mt: 2, ml:1, mr:1}}>
            <ReactAnglePicker
                value={angle}
                onChange={changeAngle}
                onAfterChange={afterChangeAngle}
            />
            <TextField 
            variant='standard'
            type="number" value={angle}
            sx={{ m: 1, width: '10ch' }}
            placeholder='Angle'
            onChange={event => afterChangeAngle(parseInt(event.target.value))}
            InputProps={{
            endAdornment: <InputAdornment position="start">°</InputAdornment>,
            label: "Angle",
            min: MIN_VALUE, max: MAX_VALUE
            }}
        />     
        </Box>
         
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditPillarDialogVisible(false)}>Abbrechen</Button>
        <Button onClick={() => {
            pillar.color = hexColor;
            pillar.angle = angle;
            savePillar(pillar)

            setEditPillarDialogVisible(false)
        }} autoFocus>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  </div>
    
    
    {/*<Dialog
                    open={open} 
                >
                    <Box style={{flexDirection: 'row', alignItems: 'center', marginHorizontal: 8}}>  
                        {<Text 
                            variant="subtitle"
                            style={{marginLeft: 8}}
                        >
                            {pillar?.name} editieren
</Text>}
                    </Box>  
                    <Dialog.ScrollArea style={{flex: 0, flexDirection: 'column', justifyContent: 'center'}}>

                            <Box sx={{mt: 2, ml:1, mr:1}}>
                                <ReactAnglePicker
                                value={angle}
                                onChange={changeAngle}
                                onAfterChange={afterChangeAngle}
                                />
                            </Box>
                            <TextField 
                                variant='standard'
                                type="number" value={angle}
                                sx={{ m: 1, width: '10ch' }}
                                placeholder='Angle'
                                onChange={event => afterChangeAngle(parseInt(event.target.value))}
                                InputProps={{
                                endAdornment: <InputAdornment position="start">°</InputAdornment>,
                                label: "Angle",
                                min: MIN_VALUE, max: MAX_VALUE
                                }}
                            />                            
                    </Dialog.ScrollArea>
                    <Dialog.Actions>
                        <Button key={"Abbrechen"} onPress={() => setEditPillarDialogVisible(false)}>Abbrechen</Button>  
                        <Button key={"Speichern"} onPress={() => {
                            pillar.color = hexColor;
                            pillar.angle = angle;
                            savePillar(pillar)
                        }}>Speichern</Button>                     
                    </Dialog.Actions> 
                </Dialog>*/}
}
export default EditPillarDialog