import React, {  }  from 'react';

export default function Footer() {
  return (
    <footer style={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white'
      }}
    >
        <img src={require("../images/TUG.png")} alt="TU Graz" title="Technische Universität Graz" className="logo" />
        <img src={require("../images/DCNA.png")} alt="DCNA" title="Disaster Competence Network Austria" className="logo" />
        <img src={require("../images/verbund.png")} alt="Verbund" title="Verbund" className="logo" />
        <img src={require("../images/Leica.png")} alt="Leica" title="Leica Geosystems" className="logo" />
        <img src={require("../images/pentamap.png")} alt="pentamap" title="pentamap gmbh" className="logo" />
        <img src={require("../images/bmlv.png")} alt="BMLV" title="Bundesministerium für Landesverteidigung" className="logo" />
    </footer>
  )}