import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import './styles/global.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

//Pages
import Dashboard from "./pages/Dashboard";
import Visualisierung from "./pages/Visualisierung";
import Export from "./pages/Export";
import About from "./pages/About";
import Karte from "./pages/Karte";
import Import from "./pages/Import";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Dynamic from "./pages/Dynamic";

const router = createBrowserRouter([
  { path: "/",
    element: <Login />,
  },
  { path: "/login",
    element: <Login />,
  },
  { path: "/reset",
    element: <Reset />,
  },
  { path: "/dashboard",
    element: <Dashboard />,
  },{
    path: "/about/",
    element: <About />,
  },{
    path: "/import/",
    element: <Import />,
  },{
    path: "/export/",
    element: <Export />,
  },{
    path: "/dynamic/",
    element: <Dynamic />,
  },{
    path: "/karte/",
    element: <Karte />,
  },{
    path: "/visualisierung/",
    element: <Visualisierung />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  </LocalizationProvider>
);